'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _containers = require('containers');

var components = _interopRequireWildcard(_containers);

var _auth = require('redux/modules/auth');

var _ApiClient = require('./helpers/ApiClient');

var _ApiClient2 = _interopRequireDefault(_ApiClient);

var _Email = require('./containers/Email/Email');

var _Email2 = _interopRequireDefault(_Email);

var _IndexRoute = require('react-router/lib/IndexRoute');

var _IndexRoute2 = _interopRequireDefault(_IndexRoute);

var _logrocket = require('logrocket');

var _logrocket2 = _interopRequireDefault(_logrocket);

var _Push = require('./containers/Push/Push');

var _Push2 = _interopRequireDefault(_Push);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouter = require('react-router');

var _reachHistory = require('./helpers/reachHistory');

var _config = require('./config');

var _config2 = _interopRequireDefault(_config);

var _redirect = require('redux-auth-wrapper/history3/redirect');

var _account = require('redux/modules/account');

var _sessionPermissions = require('./helpers/sessionPermissions');

var _partner = require('./helpers/partner');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint react/jsx-filename-extension:0 */

// TODO : Change components for containers
exports.default = function (store) {
  var restrictedRoute = function restrictedRoute(DecoratedComponent, conditions) {
    var _conditions$requireSt = conditions.requireStaff,
        requireStaff = _conditions$requireSt === undefined ? false : _conditions$requireSt,
        _conditions$requireMo = conditions.requireModal,
        requireModal = _conditions$requireMo === undefined ? false : _conditions$requireMo,
        _conditions$requiredF = conditions.requiredFeatures,
        requiredFeatures = _conditions$requiredF === undefined ? [] : _conditions$requiredF,
        _conditions$requiredP = conditions.requiredPermissions,
        requiredPermissions = _conditions$requiredP === undefined ? [] : _conditions$requiredP;

    return (0, _redirect.connectedRouterRedirect)({
      // The url to redirect user to if they fail
      redirectPath: './',
      // This prevents us from adding the query parameter when we send the user away from the login page
      allowRedirectBack: false,
      // Determine if the user is authenticated or not
      authenticatedSelector: function authenticatedSelector(state) {
        var authorized = true;
        var current_account = state.auth.current_account;
        if (requiredFeatures.length > 0 && current_account && current_account.features) {
          authorized = requiredFeatures.reduce(function (approved, feature) {
            return approved && current_account.features.includes(feature);
          }, authorized);
          if (!authorized) return false;
        }

        if (requiredPermissions.length > 0) if (!(0, _sessionPermissions.sessionPermissions)(requiredPermissions[0])) return false;

        return true;
      },
      // A nice display name for this check
      wrapperDisplayName: 'RestrictedRoute'
    })(DecoratedComponent);
  };

  var requireLogin = function requireLogin(nextState, replace, cb) {
    function checkAuth() {
      var _store$getState = store.getState(),
          data = _store$getState.auth.data;
      // ACCOUNTSELECTOR move current account to state or to a bff call


      var currentAccount = data.account_list.find(function (a) {
        return a.is_active;
      });
      var roles = currentAccount ? currentAccount.roles || [] : [];
      store.dispatch((0, _account.getAccount)());

      if (!data.logged_in || currentAccount.account_type === 'Reseller' || roles.indexOf('staff') > -1) {
        replace('./');
      } else if (data.logged_in) {
        _logrocket2.default.identify(data.user.id, {
          name: data.user.first_name + ' ' + data.user.last_name,
          email: data.email,
          account: currentAccount.name
        });
      }
      cb();
    }

    if (!(0, _auth.isLoaded)(store.getState())) {
      store.dispatch((0, _auth.load)()).then(checkAuth);
    } else {
      checkAuth();
    }
  };

  var requireReseller = function requireReseller(nextState, replace, cb) {
    function checkAuth() {
      var _store$getState2 = store.getState(),
          data = _store$getState2.auth.data;

      var currentAccount = data.account_list.find(function (a) {
        return a.is_active;
      });
      var roles = currentAccount ? currentAccount.roles || [] : [];

      if (!data.logged_in || !(currentAccount.account_type === 'Reseller') || roles.indexOf('staff') > -1) {
        replace('./');
      }
      cb();
    }

    if (!(0, _auth.isLoaded)(store.getState())) {
      store.dispatch((0, _auth.load)()).then(checkAuth);
    } else {
      checkAuth();
    }
  };

  var requireStaff = function requireStaff(nextState, replace, cb) {
    function checkAuth() {
      var _store$getState3 = store.getState(),
          data = _store$getState3.auth.data;

      var currentAccount = data.account_list.find(function (a) {
        return a.is_active;
      });
      var roles = currentAccount ? currentAccount.roles || [] : [];

      if (!data.logged_in || roles.indexOf('staff') < 0) {
        replace('./');
      }
      cb();
    }

    if (!(0, _auth.isLoaded)(store.getState())) {
      store.dispatch((0, _auth.load)()).then(checkAuth);
    } else {
      checkAuth();
    }
  };

  var requireModal = function requireModal(nextState, replace, cb) {
    if (!nextState.location) {
      console.error('Invalid application access, redirecting to root');
      replace('./');
    }
    var action = nextState.location.action;

    if (action !== 'PUSH') {
      // cant render directly to a modal
      console.warn('Actually rendering the real thing now');
      var location = nextState.location;

      if (typeof location.state === 'undefined') {
        location.state = { modal: true };
      }
      store.dispatch((0, _reachHistory.push)(nextState.location));
    }
    cb();
  };

  var createNewPropsToRender = function createNewPropsToRender() {
    var result = '';
    var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var length = 32;
    for (var i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }var data = (0, _defineProperty3.default)({}, result, result);
    return data;
  };

  var partner = (0, _partner.partnerByHost)();

  return _react2.default.createElement(
    _reactRouter.Route,
    { basename: _config2.default.basename, path: '/', component: components.App },
    _react2.default.createElement(_IndexRoute2.default, { component: components.Login }),
    _react2.default.createElement(_reactRouter.Route, { path: '/wizard/:image', component: components.Wizard }),
    _react2.default.createElement(_reactRouter.Route, { path: '/verification/password/:token', component: components.PasswordVerification }),
    _react2.default.createElement(_reactRouter.Route, { path: '/verification/email/:token', component: components.EmailVerification }),
    _react2.default.createElement(
      _reactRouter.Route,
      { path: '/account', component: components.Account, onEnter: requireModal },
      _react2.default.createElement(_IndexRoute2.default, { component: components.AccountDetail }),
      _react2.default.createElement(_reactRouter.Route, { path: '/account/user', component: components.UserDetail }),
      _react2.default.createElement(_reactRouter.Route, { path: '/account/callback', component: restrictedRoute(components.AccountCallback, { requiredFeatures: ['webhooks'], requiredPermissions: ['account:configs:callback:view'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/account/customizer', component: restrictedRoute(components.AccountCustomizer, { requiredPermissions: ['account:configs:channels:interaction'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/account/apidetail', component: restrictedRoute(components.ApiDetail, { requiredPermissions: ['account:configs:api:view'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/account/whatsapp', component: restrictedRoute(components.WhatsappConfig, { requiredFeatures: ['whatsapp'], requiredPermissions: ['account:configs:api:chat_api'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/account/emailcustomizer', component: restrictedRoute(components.EmailCustomizer, { requiredFeatures: ['channel-email'], requiredPermissions: ['account:configs:channels:sending:view'] }) })
    ),
    _react2.default.createElement(_reactRouter.Route, { path: '/users', component: restrictedRoute(components.UsersList, { requiredPermissions: ['account:user:list'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/user/sub/new', component: restrictedRoute(components.NewSubAccountUser, { requiredPermissions: ['reseller:subaccount:user:create'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/user/new', component: restrictedRoute(components.NewUser, { requiredPermissions: ['account:user:create'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/user/subaccount/edit/:user_id', component: restrictedRoute(components.EditUserSubaccount, { requiredPermissions: ['reseller:subaccount:user:edit'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/user/edit/:user_id', component: restrictedRoute(components.EditUser, { requiredPermissions: ['account:user:edit:info'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/promotions', component: restrictedRoute(components.PromotionList, { requiredFeatures: ['promotions'], requiredPermissions: ['promotions:list'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/promotion/new', component: restrictedRoute(components.NewPromotion, { requiredFeatures: ['promotions'], requiredPermissions: ['promotions:create'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/promotion/edit/:promotion_uid', component: restrictedRoute(components.NewPromotion, { requiredFeatures: ['promotions'], requiredPermissions: ['promotions:edit'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/promotion/:promotion_uid', component: restrictedRoute(components.PromotionDetail, { requiredFeatures: ['promotions'], requiredPermissions: ['promotions:view'] }) }),
    _react2.default.createElement(
      _reactRouter.Route,
      { onEnter: requireLogin },
      _react2.default.createElement(_reactRouter.Route, { path: '/short_link', component: restrictedRoute(components.ShortLinkList, { requiredFeatures: ['shortlink'], requiredPermissions: ['short:link:list'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/short_link/new', component: restrictedRoute(components.NewShortLink, { requiredFeatures: ['shortlink'], requiredPermissions: ['short:link:create'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/short_link/edit/:short_link_uid', component: restrictedRoute(components.NewShortLink, { requiredFeatures: ['shortlink'], requiredPermissions: ['short:link:edit'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/short_link/:short_link_uid', component: restrictedRoute(components.ShortLinkDetail, { requiredFeatures: ['shortlink'], requiredPermissions: ['short:link:view'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/short_link/:short_link_uid/addFile', component: restrictedRoute(components.MessageToFile, { requiredFeatures: ['shortlink'], requiredPermissions: ['short:link:view'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/short_link/:landing/:short_link_uid/addFile', component: restrictedRoute(components.MessageToFile, { requiredFeatures: ['shortlink'], requiredPermissions: ['short:link:view'] }) })
    ),
    _react2.default.createElement(
      _reactRouter.Route,
      { onEnter: requireLogin },
      _react2.default.createElement(_reactRouter.Route, { path: '/newmessage', component: components.ScheduleWizard }),
      _react2.default.createElement(_reactRouter.Route, { path: '/messages/scheduler', component: restrictedRoute(components.ScheduleWizardLoader, { requiredPermissions: ['channels:sms:send:scheduled'] }), onEnter: requireModal }),
      _react2.default.createElement(_reactRouter.Route, { path: '/messages/scheduler/resume', component: components.ScheduleWizardSummary, onEnter: requireModal }),
      _react2.default.createElement(_reactRouter.Route, { path: '/menu', component: components.MobileMenu }),
      _react2.default.createElement(_reactRouter.Route, { path: '/dashboard', component: partner == 'tigo' ? components.TigoDashboard : components.Dashboard }),
      _react2.default.createElement(
        _reactRouter.Route,
        { path: '/contacts' },
        _react2.default.createElement(_IndexRoute2.default, { component: restrictedRoute(components.Contacts, { requiredPermissions: ['contacts:list'] }) }),
        _react2.default.createElement(
          _reactRouter.Route,
          { path: '/contacts/profiles' },
          _react2.default.createElement(_IndexRoute2.default, { component: components.ContactsVariables }),
          _react2.default.createElement(_reactRouter.Route, { path: '/contacts/profiles/historical', component: restrictedRoute(components.ContactsProfilesHistorical, { requiredFeatures: ['profilings'] }) }),
          _react2.default.createElement(_reactRouter.Route, { path: '/contacts/profiles/historical/results', component: restrictedRoute(components.ContactProfilesResults, { requiredFeatures: ['profilings'] }) })
        ),
        _react2.default.createElement(
          _reactRouter.Route,
          { onEnter: requireModal },
          _react2.default.createElement(_reactRouter.Route, { path: '/profiling/choose', component: restrictedRoute(components.ProfilingSelector, { requiredFeatures: ['profilings'] }) }),
          _react2.default.createElement(_reactRouter.Route, { path: '/profiling/closed', component: restrictedRoute(components.ProfilingConfig, { requiredFeatures: ['profilings'] }) }),
          _react2.default.createElement(_reactRouter.Route, { path: '/profiling/open', component: restrictedRoute(components.ProfilingConfigOpen, { requiredFeatures: ['profilings'] }) }),
          _react2.default.createElement(_reactRouter.Route, { path: '/profiling/email', component: restrictedRoute(components.ProfilingConfigEmail, { requiredFeatures: ['profilings', 'email'] }) })
        ),
        _react2.default.createElement(_reactRouter.Route, { path: '/contacts/tags', component: restrictedRoute(components.ContactsTags, { requiredPermissions: ['tags:list'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/contacts/files', component: components.ContactsFiles }),
        _react2.default.createElement(
          _reactRouter.Route,
          { onEnter: requireModal },
          _react2.default.createElement(_reactRouter.Route, { path: '/contacts/fileDetail', component: components.ContactFileDetail, onEnter: requireModal }),
          _react2.default.createElement(_reactRouter.Route, { path: '/contacts/choose', component: components.NewContactSelector }),
          _react2.default.createElement(_reactRouter.Route, { path: '/contacts/add', component: restrictedRoute(components.AddContact, { requiredPermissions: ['contacts:create:individual'] }) }),
          _react2.default.createElement(_reactRouter.Route, { path: '/contacts/addMessage', component: restrictedRoute(components.ContactsAddMessage, { requiredPermissions: ['channels:sms:send:single'] }) }),
          _react2.default.createElement(_reactRouter.Route, { path: '/contacts/addTags', component: restrictedRoute(components.ContactsAddTags, { requiredPermissions: ['contacts:tags:add'] }) }),
          _react2.default.createElement(_reactRouter.Route, { path: '/contacts/upload', component: restrictedRoute(components.ContactsUpload, { requiredPermissions: ['contacts:create:file'] }) })
        )
      ),
      _react2.default.createElement(_reactRouter.Route, { key: 'email', exact: true, path: '/email', component: restrictedRoute(function () {
          return _react2.default.createElement(_Email2.default, null);
        }, { requiredPermissions: ['channels:email:send:single'] }) }),
      _react2.default.createElement(_reactRouter.Route, { key: 'file', exact: true, path: '/email/file', component: restrictedRoute(function () {
          return _react2.default.createElement(_Email2.default, { file: true });
        }, { requiredPermissions: ['channels:email:send:file'] }) }),
      _react2.default.createElement(_reactRouter.Route, { key: 'push', exact: true, path: '/push/single',
        component: restrictedRoute(function () {
          return _react2.default.createElement(_Push2.default, { single: true });
        }, { requiredPermissions: ['channels:push:send:single'] }) }),
      _react2.default.createElement(_reactRouter.Route, { key: 'push', exact: true, path: '/push/file',
        component: restrictedRoute(function () {
          return _react2.default.createElement(_Push2.default, { file: true });
        }, { requiredPermissions: ['channels:push:send:file'] }) }),
      _react2.default.createElement(_reactRouter.Route, { key: 'whatsapp', exact: true, path: '/whatsapp', component: components.Whatsapp }),
      _react2.default.createElement(
        _reactRouter.Route,
        { path: '/messages' },
        _react2.default.createElement(_IndexRoute2.default, { component: restrictedRoute(components.MessagesSent, { requiredPermissions: ['transactions:list:outgoing'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/detail', component: restrictedRoute(components.MessageDetail, { requiredPermissions: ['transactions:view'] }), onEnter: requireModal }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/file/detail', component: restrictedRoute(components.MessageDetail, { requiredPermissions: ['transactions:files:view'] }), onEnter: requireModal }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/file/scheduled/detail', component: restrictedRoute(components.MessageDetail, { requiredPermissions: ['transactions:file:scheduled:detail'] }), onEnter: requireModal }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/calendar/detail', component: restrictedRoute(components.MessageDetail, { requiredPermissions: ['transactions:scheduled:view'] }), onEnter: requireModal }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/detail/:transactionid', component: restrictedRoute(components.MessageErrorDetail, { requiredPermissions: ['transactions:error:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/byUsers', component: restrictedRoute(components.MessageByUsers, { requiredPermissions: ['transactions:list:incoming'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/received', component: restrictedRoute(components.MessagesReceived, { requiredPermissions: ['transactions:list:incoming'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/calendar', component: restrictedRoute(components.MessagesCalendar, { requiredPermissions: ['transactions:scheduled:list'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/files', component: restrictedRoute(components.MessagesFiles, { requiredPermissions: ['transactions:files:list'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/templates', component: restrictedRoute(components.MessagesTemplates, { requiredPermissions: ['channels:sms:template:list'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/templates/new', component: restrictedRoute(components.NewMessageTemplate, { requiredPermissions: ['channels:sms:template:create'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/templates/duplicate', component: restrictedRoute(components.NewMessageTemplate, { requiredPermissions: ['channels:sms:template:duplicate'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/templates/share', component: restrictedRoute(components.MessageTemplateShare, { requiredPermissions: ['channels:sms:template:share'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/templates/report', component: restrictedRoute(components.MessageTemplateReport, { requiredPermissions: ['channels:sms:template:share'] }) })
      ),
      _react2.default.createElement(
        _reactRouter.Route,
        { path: '/messages/add', onEnter: requireModal },
        _react2.default.createElement(_IndexRoute2.default, { component: restrictedRoute(components.MessageToContacts, { requiredFeatures: ['sms'], requiredPermissions: ['channels:sms:send:single'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/addTags', component: restrictedRoute(components.MessageToTags, { requiredFeatures: ['sms'], requiredPermissions: ['channels:sms:send:tag'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/addFile', component: restrictedRoute(components.MessageToFile, { requiredFeatures: ['sms'], requiredPermissions: ['channels:sms:send:file'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/message/scheduled', component: restrictedRoute(components.ScheduledDetail, { requiredPermissions: ['channels:sms:send:scheduled'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/summary', component: components.ScheduledSummary }),
        _react2.default.createElement(_reactRouter.Route, { path: '/messages/confirmation', component: restrictedRoute(components.Confirmation, { requiredPermissions: ['transactions:scheduled:delete'] }) })
      ),
      _react2.default.createElement(_reactRouter.Route, { path: '/chat/:id', component: components.MobileChat }),
      _react2.default.createElement(
        _reactRouter.Route,
        { path: '/contacts/:id', component: restrictedRoute(components.Contact, { requiredPermissions: ['contacts:view'] }), onEnter: requireModal },
        _react2.default.createElement(_IndexRoute2.default, { component: components.ContactIndex }),
        _react2.default.createElement(_reactRouter.Route, { path: '/contacts/:id/tags', component: restrictedRoute(components.ContactTags, { requiredPermissions: ['contacts:tags:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/contacts/:id/vars', component: restrictedRoute(components.ContactVars, { requiredPermissions: ['contacts:edit'] }) })
      ),
      _react2.default.createElement(_reactRouter.Route, { path: '/reports', component: restrictedRoute(components.Reports, { requiredPermissions: ['account:reports:balance:view'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/reports/report', component: components.Report }),
      _react2.default.createElement(
        _reactRouter.Route,
        { onEnter: requireModal },
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/requestTransactions', component: restrictedRoute(components.TransactionsRequest, { requiredPermissions: ['account:reports:transactions:download'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/dailyTransactions', component: restrictedRoute(components.report.DailyMessages, { requiredPermissions: ['account:reports:balance:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/dailyMessages', component: restrictedRoute(components.report.DailyMessages, { requiredPermissions: ['account:reports:balance:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/dailyHarassment', component: restrictedRoute(components.Harassment, { requiredPermissions: ['account:reports:balance:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/dailyErrors', component: restrictedRoute(components.report.DailyMessages, { requiredPermissions: ['account:reports:balance:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/dailyWeb', component: restrictedRoute(components.report.DailyMessages, { requiredPermissions: ['account:reports:balance:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/balanceHistory', component: restrictedRoute(components.BalanceHistory, { requiredPermissions: ['account:reports:balance:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/dailyPush', component: components.report.DailyMessages }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/dailyWhatsapp', component: components.report.DailyMessages }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/dailyRcs', component: components.report.DailyMessages }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/dailyEmail', component: restrictedRoute(components.report.DailyMessages, { requiredFeatures: ['email'], requiredPermissions: ['account:reports:balance:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/cancelled', component: restrictedRoute(components.report.Cancelled, { requiredPermissions: ['account:reports:balance:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reports/charges', component: restrictedRoute(components.PrepaidCharges, { requiredPermissions: ['subaccount:charges:list'] }) })
      ),
      _react2.default.createElement(
        _reactRouter.Route,
        { path: '/integrations/meraki' },
        _react2.default.createElement(_IndexRoute2.default, { component: restrictedRoute(components.integrations.meraki.MerakiList, { requiredFeatures: ['meraki'] }) }),
        _react2.default.createElement(
          _reactRouter.Route,
          { onEnter: requireModal },
          _react2.default.createElement(_reactRouter.Route, { path: '/integrations/meraki/profiling', component: restrictedRoute(components.integrations.meraki.MerakiProfiling, { requiredFeatures: ['meraki'] }) }),
          _react2.default.createElement(_reactRouter.Route, { path: '/integrations/meraki/results', component: restrictedRoute(components.integrations.meraki.MerakiResults, { requiredFeatures: ['meraki'] }) })
        )
      ),
      _react2.default.createElement(_reactRouter.Route, { path: '/help', component: components.Help }),
      _react2.default.createElement(_reactRouter.Route, { path: '/resthelp', component: components.RestHelp }),
      _react2.default.createElement(_reactRouter.Route, { path: '/httphelp', component: components.HttpHelp })
    ),
    _react2.default.createElement(
      _reactRouter.Route,
      { onEnter: requireReseller },
      _react2.default.createElement(_reactRouter.Route, { path: '/reseller', component: restrictedRoute(components.reseller.AccountList, { requiredPermissions: ['reseller:subaccount:list'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/reseller/users', component: restrictedRoute(components.reseller.UserList, { requiredPermissions: ['reseller:subaccount:user:list'] }) }),
      _react2.default.createElement(
        _reactRouter.Route,
        { onEnter: requireModal },
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/subaccount', component: restrictedRoute(components.reseller.SubAccount, { requiredPermissions: ['reseller:subaccount:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/subaccount/edit', component: restrictedRoute(components.reseller.SubAccountEdit, { requiredPermissions: ['reseller:subaccount:edit'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/subaccount/new', component: restrictedRoute(components.reseller.SubAccountEdit, { requiredPermissions: ['reseller:subaccount:create'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/policies', component: restrictedRoute(components.reseller.PolicyList, { requiredPermissions: ['reseller:policies:antispam:list'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/policy', component: restrictedRoute(components.reseller.Policy, { requiredPermissions: ['reseller:policies:antispam:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/policy/edit', component: restrictedRoute(components.reseller.PolicyEdit, { requiredPermissions: ['reseller:policies:antispam:edit'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/policy/new', component: restrictedRoute(components.reseller.PolicyEdit, { requiredPermissions: ['reseller:policies:antispam:create'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/reports', component: components.reseller.Reports }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/dailyHarassment', component: restrictedRoute(components.Harassment, { requiredPermissions: ['reseller:policies:antispam:list'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/balanceHistory', component: restrictedRoute(components.BalanceHistory, { requiredPermissions: ['reseller:subaccount:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/balance', component: restrictedRoute(components.reseller.BalanceReport, { requiredPermissions: ['reseller:subaccount:reports:balance:view'] }) }),
        _react2.default.createElement(_reactRouter.Route, { path: '/reseller/subaccount/charges', component: restrictedRoute(components.PrepaidCharges, { requiredPermissions: ['subaccount:charges:list'] }) })
      )
    ),
    _react2.default.createElement(
      _reactRouter.Route,
      { onEnter: requireStaff },
      _react2.default.createElement(_reactRouter.Route, { path: '/staff/messages', component: components.staff.Staff }),
      _react2.default.createElement(
        _reactRouter.Route,
        { path: '/staff/messages/add', onEnter: requireModal },
        _react2.default.createElement(_IndexRoute2.default, { component: components.MessageToContacts }),
        _react2.default.createElement(_reactRouter.Route, { path: '/staff/messages/addTags', component: components.MessageToTags })
      ),
      _react2.default.createElement(_reactRouter.Route, { path: '/staff/messages/detail', component: components.MessageDetail, onEnter: requireModal }),
      _react2.default.createElement(_reactRouter.Route, { path: '/staff/messages/detail/:transactionid', component: components.MessageErrorDetail })
    ),
    _react2.default.createElement(_reactRouter.Route, { path: '/success', component: components.Success }),
    _react2.default.createElement(_reactRouter.Route, { path: '/fail', component: components.Fail }),
    _react2.default.createElement(_reactRouter.Route, { path: '/save', component: components.Save }),
    _react2.default.createElement(_reactRouter.Route, { path: '/notice', component: components.Notice }),
    _react2.default.createElement(_reactRouter.Route, { path: '/queued', component: components.Queued }),
    _react2.default.createElement(_reactRouter.Route, { path: '/profiling', component: restrictedRoute(components.ContactsVariables, { requiredFeatures: ['profilings'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/studies', component: restrictedRoute(components.StudiesAll, { requiredFeatures: ['studies'], requiredPermissions: ['study:list'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/studies/inprogress', component: restrictedRoute(components.StudiesInprogress, { requiredFeatures: ['studies'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/studies/notstarted', component: restrictedRoute(components.StudiesNotstarted, { requiredFeatures: ['studies'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/studies/stopped', component: restrictedRoute(components.StudiesStopped, { requiredFeatures: ['studies'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/studies/api', component: restrictedRoute(components.ApiStudy, { requiredFeatures: ['studies', 'webhooks'], requiredPermissions: ['study:create:api'] }) }),
    _react2.default.createElement(_reactRouter.Route, { path: '/studies/share', component: restrictedRoute(components.StudyShare, { requiredFeatures: ['studies'], requiredPermissions: ['study:start'] }) }),
    _react2.default.createElement(
      _reactRouter.Route,
      { onEnter: requireModal },
      _react2.default.createElement(_reactRouter.Route, { path: '/studies/study', component: restrictedRoute(components.Study, { requiredFeatures: ['studies'], requiredPermissions: ['study:create:private'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/studies/study/edit', component: restrictedRoute(components.Study, { requiredFeatures: ['studies'], requiredPermissions: ['study:edit'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/studies/study/copy', component: restrictedRoute(components.Study, { requiredFeatures: ['studies'], requiredPermissions: ['study:duplicate'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/studies/choose', component: restrictedRoute(components.NewStudySelector, { requiredFeatures: ['studies'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/studies/open', component: restrictedRoute(components.OpenStudy, { requiredFeatures: ['studies'], requiredPermissions: ['study:create:public'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/studies/schedule', component: restrictedRoute(components.StudySchedule, { requiredFeatures: ['studies'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/studies/results', component: restrictedRoute(components.StudyResults, { requiredFeatures: ['studies'], requiredPermissions: ['study:view'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/studies/qrs', component: restrictedRoute(components.QrList, { requiredFeatures: ['studies'] }) })
    ),
    _react2.default.createElement(_reactRouter.Route, { path: '/campaigns', component: restrictedRoute(components.Campaigns, { requiredFeatures: ['campaigns'], requiredPermissions: ['campaign:list'] }) }),
    _react2.default.createElement(
      _reactRouter.Route,
      { onEnter: requireModal },
      _react2.default.createElement(_reactRouter.Route, { path: '/campaigns/campaign', component: restrictedRoute(components.Campaign, { requiredFeatures: ['campaigns'], requiredPermissions: ['campaign:create'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/campaigns/campaign/copy', component: restrictedRoute(components.Campaign, { requiredFeatures: ['campaigns'], requiredPermissions: ['campaign:copy'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/campaigns/campaign/edit', component: restrictedRoute(components.Campaign, { requiredFeatures: ['campaigns'], requiredPermissions: ['campaign:edit'] }) }),
      _react2.default.createElement(_reactRouter.Route, { path: '/campaigns/results', component: restrictedRoute(components.CampaignResults, { requiredFeatures: ['campaigns'], requiredPermissions: ['campaign:view'] }) })
    ),
    _config2.default.allowSSO && _react2.default.createElement(_reactRouter.Route, { path: '/callback', component: components.CallBack }),
    _react2.default.createElement(_reactRouter.Route, { path: '*', component: components.NotFound, status: 404 })
  );
};

module.exports = exports['default'];