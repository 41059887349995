'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp; /* eslint-disable */

var _partner = require('../../helpers/partner');

var _auth = require('redux/modules/auth');

var _reactGoogleRecaptcha = require('react-google-recaptcha');

var _reactGoogleRecaptcha2 = _interopRequireDefault(_reactGoogleRecaptcha);

var _reactHelmet = require('react-helmet');

var _reactHelmet2 = _interopRequireDefault(_reactHelmet);

var _reactRouter = require('react-router');

var _components = require('components');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _ravenJs = require('raven-js');

var _ravenJs2 = _interopRequireDefault(_ravenJs);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _config = require('../../config');

var _config2 = _interopRequireDefault(_config);

var _reactRedux = require('react-redux');

var _reachHistory = require('../../helpers/reachHistory');

var _themeEnabled = require('../../helpers/themeEnabled');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var icons = {
  sms: require('./sms_white.svg')
};

var md = {
  eye: require('react-icons/lib/md/remove-red-eye'),
  password: require('react-icons/lib/md/lock-outline'),
  message: require('react-icons/lib/md/mail-outline')
};

var recaptchaRef = _react2.default.createRef();
var Login = (_dec = (0, _reduxConnect.asyncConnect)([{
  key: "auth",
  promise: function promise(_ref) {
    var _ref$store = _ref.store,
        dispatch = _ref$store.dispatch,
        getState = _ref$store.getState;

    if (!(0, _auth.isLoaded)(getState())) {
      return dispatch((0, _auth.load)());
    }
    return null;
  }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
  return { accounts: state.auth.data.account_list, auth: state.auth, isMobile: state.mobile.isMobile };
}, { login: _auth.login, logout: _auth.logout, loadAuth: _auth.load, pushState: _reachHistory.push, passwordRecover: _auth.recover, twoFaToken: _auth.twoFaToken, twoFaTokenVerify: _auth.twoFaTokenVerify }), _dec(_class = _dec2(_class = (_temp = _class2 = function (_React$Component) {
  (0, _inherits3.default)(Login, _React$Component);

  function Login(props) {
    (0, _classCallCheck3.default)(this, Login);

    var _this = (0, _possibleConstructorReturn3.default)(this, (Login.__proto__ || (0, _getPrototypeOf2.default)(Login)).call(this, props));

    _this.cleanLocalStorage = function () {
      localStorage.removeItem('SUSPENDED');
      localStorage.removeItem('LOGIN_ERROR');
      _this.setState({
        user_suspended: false,
        account_suspended: false,
        user_temporarily_suspended: false,
        user_not_logged: false,
        message: ''
      });
    };

    _this.state = {
      recovering: false,
      passwordType: 'password',
      message: '',
      twoFA: false,
      disableCodeInput: false,
      timer: 30,
      showTwoFAOptions: false,
      user_suspended: false,
      account_suspended: false,
      user_temporarily_suspended: false,
      token: null
    };
    return _this;
  }

  (0, _createClass3.default)(Login, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      this.setState({
        user_suspended: localStorage.getItem('SUSPENDED') === 'USER_SUSPENDED',
        account_suspended: localStorage.getItem('SUSPENDED') === 'ACCOUNT_SUSPENDED',
        user_temporarily_suspended: localStorage.getItem('SUSPENDED') === 'USER_TEMPORARILY_SUSPENDED',
        user_not_logged: localStorage.getItem('LOGIN_ERROR') === 'USER_NOT_LOGGED',
        message: localStorage.getItem('LOGIN_ERROR') === 'USER_NOT_LOGGED' ? (0, _translations2.default)('LOGIN_ERROR') : ''
      });
      this.interval = setInterval(function () {
        if (_this2.state.timer > 0) {
          _this2.setState(function (prevState) {
            return {
              timer: prevState.timer - 1
            };
          });
        } else {
          _this2.setState({ showTwoFAOptions: true });
          clearInterval(_this2.interval);
        }
      }, 1000);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      clearInterval(this.interval);
    }
  }, {
    key: 'resetTimer',
    value: function resetTimer() {
      var _this3 = this;

      // Limpiar el intervalo existente
      clearInterval(this.interval);

      // Reiniciar el estado del temporizador y volver a iniciar el intervalo
      this.setState({ timer: 30, showTwoFAOptions: false }, function () {
        _this3.interval = setInterval(function () {
          if (_this3.state.timer > 0) {
            _this3.setState(function (prevState) {
              return {
                timer: prevState.timer - 1
              };
            });
          } else {
            _this3.setState({ showTwoFAOptions: true });
            clearInterval(_this3.interval);
          }
        }, 1000);
      });
    }
  }, {
    key: 'handleRecaptcha',
    value: function handleRecaptcha(e) {
      var _this4 = this;

      e.preventDefault();
      var response_token = recaptchaRef.current.getValue();
      if (response_token) {
        this.handleSubmit(response_token);
      } else {
        recaptchaRef.current.execute().then(function (token) {
          if (token) {
            _this4.handleSubmit(token);
          }
        }).catch(function (error) {
          console.error('Error executing recaptcha', error);
        });
      }
    }
  }, {
    key: 'onChange',
    value: function onChange(response_token) {
      if (response_token) {
        this.handleSubmit(response_token);
      }
    }
  }, {
    key: 'onErrored',
    value: function onErrored(error) {
      console.error("Error executing recaptcha", error);
    }
  }, {
    key: 'handleSubmit',
    value: function handleSubmit(response_token) {
      var _this5 = this;

      var username = this.refs.username.value;
      var password = this.refs.password.value;

      if (__DEVELOPMENT__ && !username) {
        username = 'interactua@ubiquo.io';
        password = '1234';
      }

      this.props.login(username, password, response_token).then(function (r) {
        var suspensionError = r.login_error;
        if (['USER_SUSPENDED', 'ACCOUNT_SUSPENDED', 'USER_TEMPORARILY_SUSPENDED'].includes(suspensionError)) {
          localStorage.setItem('SUSPENDED', suspensionError);
          _this5.setState((0, _extends3.default)({}, _this5.state, {
            user_suspended: ['USER_SUSPENDED'].includes(suspensionError),
            account_suspended: ['ACCOUNT_SUSPENDED'].includes(suspensionError),
            user_temporarily_suspended: ['USER_TEMPORARILY_SUSPENDED'].includes(suspensionError)
          }));
        } else {
          if (!r.logged_in) {
            localStorage.setItem('LOGIN_ERROR', 'USER_NOT_LOGGED');
            _this5.setState((0, _extends3.default)({}, _this5.state, {
              user_not_logged: false
            }));
          }
        }
        if (!['USER_SUSPENDED', 'ACCOUNT_SUSPENDED', 'USER_TEMPORARILY_SUSPENDED'].includes(suspensionError) && r.logged_in) {
          _this5.cleanLocalStorage();
        }
        var current_account = r.account_list.find(function (a) {
          return a.is_active;
        });
        if (_config2.default.allow2FA) {
          _this5.setState({
            usernameOriginal: username,
            twoFA: true,
            username: _this5.replaceToAtWithAsterisks(username),
            phoneNumber: _this5.replaceWithAsterisksExceptLastTwo(r.user.msisdn),
            user: r.user,
            current_account: current_account
          });
          if (!r.user.allow_sms_2fa) {
            _this5.sendCode(true);
          }
        } else {
          if (current_account && current_account.account_type == "Reseller") {
            _this5.props.pushState('/reseller/');
          } else {
            if (current_account && current_account.roles.indexOf('staff') > -1) {
              _this5.props.pushState('/staff/messages/');
            }
            _this5.props.pushState('/dashboard');
          }
        }
      }).catch(function (e) {
        console.error('failed to log in', e);
        _this5.props.logout();
        var context = {
          username: username,
          inputval: password
        };

        _ravenJs2.default.captureMessage('User failed to log in', {
          extra: context
        });

        _this5.setState((0, _extends3.default)({}, _this5.state, {
          message: (0, _translations2.default)('LOGIN_ERROR')
        }));
      });
    }
  }, {
    key: 'replaceToAtWithAsterisks',
    value: function replaceToAtWithAsterisks(email) {
      if (typeof email !== "string" || email.length < 4) {
        return email;
      }

      var atIndex = email.indexOf("@");
      if (atIndex === -1) {
        return email;
      }

      var usernamePart = email.slice(0, atIndex);
      var domainPart = email.slice(atIndex);

      var usernameReplaced = usernamePart.slice(0, 3) + usernamePart.slice(3).replace(/./g, "*");

      return usernameReplaced + domainPart;
    }
  }, {
    key: 'replaceWithAsterisksExceptLastTwo',
    value: function replaceWithAsterisksExceptLastTwo(str) {
      if (typeof str !== "string" || str.length <= 2) {
        return str;
      }

      // Reemplazar todos los caracteres excepto los últimos dos
      var replacedPart = str.slice(0, -2).replace(/./g, "*");
      var lastTwoChars = str.slice(-2);

      return replacedPart + lastTwoChars;
    }
  }, {
    key: 'handleLogout',
    value: function handleLogout() {
      this.props.logout();
    }
  }, {
    key: 'handleSubmitRecovery',
    value: function handleSubmitRecovery() {
      var _this6 = this;

      var username = this.refs.username.value;
      this.props.passwordRecover(username, (0, _partner.partnerByHost)()).then(function () {
        _this6.setState((0, _extends3.default)({}, _this6.state, {
          recoverting: false,
          message: (0, _translations2.default)('RECOVER EMAIL SENT')
        }));
      }).catch(function () {
        // this should never happen
        _this6.setState((0, _extends3.default)({}, _this6.state, {
          recovering: false,
          message: 'RECOVER EMAIL NOT SENT'
        }));
      });
    }
  }, {
    key: 'handleRecovery',
    value: function handleRecovery() {
      this.setState((0, _extends3.default)({}, this.state, {
        recovering: !this.state.recovering,
        message: ''
      }));
    }
  }, {
    key: 'handleSSO',
    value: function handleSSO() {
      sessionStorage.setItem("ssoLogin", true);
      window.location.replace("/v5/login?sso=true");
    }
  }, {
    key: 'sendCode',
    value: function sendCode(byEmail) {
      this.setState({
        selectedMethod: true,
        byEmail: byEmail
      });
      this.handleRequestCode(byEmail);
    }
  }, {
    key: 'handleSubmitTwoFA',
    value: function handleSubmitTwoFA() {
      var _this7 = this;

      var code = this.refs.code.value;
      var current_account = this.state.current_account;
      this.props.twoFaTokenVerify(this.state.key, code).then(function (res) {
        if (!res.valid_token && !res.limit_attempts) {
          _this7.setState((0, _extends3.default)({}, _this7.state, {
            message: (0, _translations2.default)('INVALID SECURITY CODE')
          }));
        }
        if (res.limit_attempts) {
          _this7.setState({
            disableCodeInput: true,
            message: (0, _translations2.default)('SECURITY CODE CHECK LIMIT REACHED')
          });
        }
        if (res.valid_token) {
          _this7.props.loadAuth().then(function (respuesta) {
            if (current_account.account_type == "Reseller") {
              _this7.props.pushState('/reseller/');
            } else {
              if (current_account.roles.indexOf('staff') > -1) {
                _this7.props.pushState('/staff/messages/');
              }
              _this7.props.pushState('/dashboard');
            }
          });
        } else {}
      }).catch(function (error) {
        // this should never happen
        console.log(error);
      });
    }
  }, {
    key: 'handleRequestCodeInit',
    value: function handleRequestCodeInit(email) {
      var _this8 = this;

      if (email) {
        this.props.twoFaToken({ type: 'email' }).then(function (res) {
          _this8.setState({
            key: res.key
          });
        }).catch(function () {
          // this should never happen

        });
      }
    }
  }, {
    key: 'handleRequestCode',
    value: function handleRequestCode() {
      var _this9 = this;

      var byEmail = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

      var body = { type: byEmail ? 'email' : 'sms' };
      this.props.twoFaToken(body).then(function (res) {
        _this9.setState({
          key: res.key,
          disableCodeInput: false,
          message: undefined
        });
        _this9.resetTimer();
      }).catch(function () {
        // this should never happen

      });
    }
  }, {
    key: 'handleCancelTwoFA',
    value: function handleCancelTwoFA() {
      this.setState({
        twoFA: false,
        selectedMethod: false
      });
      this.props.logout();
    }
  }, {
    key: 'togglePassword',
    value: function togglePassword() {
      this.setState((0, _extends3.default)({}, this.state, {
        passwordType: this.state.passwordType === 'password' ? 'text' : 'password'
      }));
      _reactDom2.default.findDOMNode(this.refs.password).focus();
    }
  }, {
    key: 'renderFormNotificame',
    value: function renderFormNotificame(stylesNotificame) {
      var _this10 = this;

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'div',
          { className: stylesNotificame.titleDiv },
          _react2.default.createElement(
            'font',
            { className: _config2.default.allow2FA && this.state.twoFA ? stylesNotificame.twoFaTitle : stylesNotificame.title },
            _config2.default.allow2FA && this.state.twoFA ? (0, _translations2.default)('TWO FA TITLE') : this.state.recovering ? (0, _translations2.default)('RECOVER PASSWORD') : (0, _translations2.default)('CLIENT LOGIN')
          ),
          _config2.default.allow2FA && this.state.twoFA ? this.state.selectedMethod ? this.state.byEmail ? _react2.default.createElement(
            'p',
            { className: stylesNotificame.twoFaDescription },
            (0, _translations2.default)('TWO FA DESCRIPTION'),
            ' ',
            this.state.username ? this.state.username : ""
          ) : _react2.default.createElement(
            'p',
            { className: stylesNotificame.twoFaDescription },
            (0, _translations2.default)('TWO FA DESCRIPTION SMS'),
            ' ',
            this.state.phoneNumber
          ) : null : null
        ),
        this.state.message ? _react2.default.createElement(
          'div',
          { className: stylesNotificame.error, 'data-actualerror': this.props.auth.data },
          _react2.default.createElement(
            'span',
            { className: stylesNotificame.errortext },
            this.state.message
          )
        ) : null,
        _react2.default.createElement(
          'div',
          null,
          _config2.default.allow2FA && this.state.twoFA ? null : _react2.default.createElement(
            'div',
            { className: stylesNotificame.username },
            _react2.default.createElement(
              'div',
              { className: stylesNotificame.icons },
              _react2.default.createElement('input', { type: 'email', ref: 'username', placeholder: (0, _translations2.default)('EMAIL'), autoComplete: 'username' }),
              _react2.default.createElement(md.message, { size: 15, color: '#51c172' })
            )
          ),
          _config2.default.allow2FA && this.state.twoFA ? !this.state.selectedMethod ? _react2.default.createElement(
            'div',
            { className: 'mb-5' },
            _react2.default.createElement(
              'p',
              null,
              (0, _translations2.default)('SELECT METHOD FOR SECURITY CODE'),
              ' '
            ),
            _react2.default.createElement(
              'button',
              { className: stylesNotificame.button2FA, onClick: function onClick() {
                  return _this10.sendCode(false);
                } },
              _react2.default.createElement('i', { className: stylesNotificame.icon, dangerouslySetInnerHTML: { __html: icons['sms'] } }),
              ' Mensaje de texto'
            ),
            _react2.default.createElement(
              'button',
              { className: stylesNotificame.button, onClick: function onClick() {
                  return _this10.sendCode(true);
                } },
              _react2.default.createElement(md.message, { size: 25, color: 'white', className: 'mr-2' }),
              ' Correo electr\xF3nico'
            )
          ) : _react2.default.createElement(
            'div',
            { className: stylesNotificame.code, style: { marginTop: "5px" } },
            _react2.default.createElement('input', { type: 'number', ref: 'code', placeholder: (0, _translations2.default)('2FA CODE'), autoComplete: 'code', disabled: this.state.disableCodeInput })
          ) : this.state.recovering ? null : _react2.default.createElement(
            'div',
            { className: stylesNotificame.password },
            _react2.default.createElement(
              'div',
              { className: stylesNotificame.icons },
              _react2.default.createElement('input', { type: this.state.passwordType, ref: 'password', placeholder: (0, _translations2.default)('PASSWORD'), autoComplete: 'current-password' }),
              _react2.default.createElement(md.password, { size: 15, color: '#51c172' })
            ),
            this.props.isMobile ? _react2.default.createElement(md.eye, { size: 20, className: stylesNotificame.show, color: '#606060', onClick: this.togglePassword.bind(this) }) : null
          )
        ),
        _config2.default.allow2FA && this.state.twoFA ? _react2.default.createElement(
          'p',
          null,
          this.state.selectedMethod && _react2.default.createElement(
            'button',
            { className: stylesNotificame.button, onClick: this.handleSubmitTwoFA.bind(this), disabled: this.state.disableCodeInput },
            (0, _translations2.default)('SEND')
          ),
          this.state.showTwoFAOptions && this.state.selectedMethod && _react2.default.createElement(
            'a',
            { className: stylesNotificame.recovery + ' ' + stylesNotificame.twofa, onClick: function onClick() {
                return _this10.handleRequestCode(_this10.state.byEmail);
              } },
            (0, _translations2.default)('REQUEST CODE')
          ),
          this.state.showTwoFAOptions && this.state.selectedMethod && this.state.user.allow_sms_2fa && _react2.default.createElement(
            'a',
            { className: stylesNotificame.recovery + ' ' + stylesNotificame.twofa, onClick: function onClick() {
                return _this10.setState({ selectedMethod: false });
              } },
            (0, _translations2.default)('CHANGE DELIVERY METHOD'),
            ' '
          ),
          !this.state.showTwoFAOptions && this.state.selectedMethod && _react2.default.createElement(
            'a',
            { className: stylesNotificame.recovery + ' ' + stylesNotificame.twofa },
            (0, _translations2.default)('REQUEST NEW CODE'),
            '  (',
            this.state.timer,
            ')'
          ),
          _react2.default.createElement(
            'a',
            { className: stylesNotificame.recovery, onClick: this.handleCancelTwoFA.bind(this) },
            (0, _translations2.default)('CANCEL')
          )
        ) : this.state.recovering ? _react2.default.createElement(
          'p',
          null,
          _react2.default.createElement(
            'button',
            { className: stylesNotificame.button, onClick: this.handleSubmitRecovery.bind(this) },
            (0, _translations2.default)('REQUEST RECOVERY')
          ),
          _react2.default.createElement(
            'a',
            { className: stylesNotificame.recovery, onClick: this.handleRecovery.bind(this) },
            (0, _translations2.default)('CLIENT LOGIN')
          )
        ) : _react2.default.createElement(
          'p',
          null,
          this.renderRecaptcha(),
          _react2.default.createElement(
            'button',
            { className: stylesNotificame.button, onClick: this.handleRecaptcha.bind(this) },
            (0, _translations2.default)('LOGIN')
          ),
          _config2.default.allowSSO && _react2.default.createElement(
            'a',
            { className: stylesNotificame.recovery + ' ' + stylesNotificame.sso, onClick: this.handleSSO.bind(this) },
            "Ingresar con SSO"
          ),
          _react2.default.createElement(
            'a',
            { className: stylesNotificame.recovery, onClick: this.handleRecovery.bind(this) },
            (0, _translations2.default)('RECOVER PASSWORD')
          )
        )
      );
    }
  }, {
    key: 'renderTigoForm',
    value: function renderTigoForm(styles) {
      var _this11 = this;

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          'p',
          { className: _config2.default.allow2FA && this.state.twoFA ? styles.twoFaTitle : styles.tigoTitle },
          _config2.default.allow2FA && this.state.twoFA ? (0, _translations2.default)('TWO FA TITLE') : this.state.recovering ? (0, _translations2.default)('RECOVER PASSWORD') : (0, _translations2.default)("ENTER YOUR INFO")
        ),
        _config2.default.allow2FA && this.state.twoFA ? this.state.selectedMethod ? this.state.byEmail ? _react2.default.createElement(
          'p',
          { className: styles.twoFaDescription },
          (0, _translations2.default)('TWO FA DESCRIPTION'),
          ' ',
          this.state.username ? this.state.username : ""
        ) : _react2.default.createElement(
          'p',
          { className: styles.twoFaDescription },
          (0, _translations2.default)('TWO FA DESCRIPTION SMS'),
          ' ',
          this.state.phoneNumber
        ) : null : null,
        this.state.message ? _react2.default.createElement(
          'div',
          { className: styles.error, 'data-actualerror': this.props.auth.data },
          _react2.default.createElement(
            'span',
            { className: styles.errortext },
            this.state.message
          )
        ) : null,
        _react2.default.createElement(
          'div',
          null,
          _config2.default.allow2FA && this.state.twoFA ? null : _react2.default.createElement(
            'div',
            { className: styles.username },
            _react2.default.createElement(
              'div',
              { className: styles.icons },
              _react2.default.createElement(
                'div',
                { className: styles.inputTitle },
                (0, _translations2.default)('EMAIL')
              ),
              _react2.default.createElement('input', { type: 'email', ref: 'username', placeholder: (0, _translations2.default)('EMAIL'), autoComplete: 'username' })
            )
          ),
          _config2.default.allow2FA && this.state.twoFA ? !this.state.selectedMethod ? _react2.default.createElement(
            'div',
            { className: styles.tigoLogin2FA },
            _react2.default.createElement(
              'p',
              { className: styles.twoFaDescription },
              (0, _translations2.default)('SELECT METHOD FOR SECURITY CODE'),
              ' '
            ),
            _react2.default.createElement(
              'button',
              { className: styles.button, onClick: function onClick() {
                  return _this11.sendCode(false);
                } },
              _react2.default.createElement('i', { className: styles.icon, dangerouslySetInnerHTML: { __html: icons['sms'] } }),
              ' Mensaje de texto'
            ),
            _react2.default.createElement(
              'button',
              { className: styles.button, onClick: function onClick() {
                  return _this11.sendCode(true);
                } },
              _react2.default.createElement(md.message, { className: styles.icon, size: 25, color: 'white' }),
              ' Correo electr\xF3nico'
            )
          ) : _react2.default.createElement(
            'div',
            { className: styles.code, style: { marginTop: "5px" } },
            _react2.default.createElement('input', { type: 'number', ref: 'code', placeholder: (0, _translations2.default)('2FA CODE'), autoComplete: 'code', disabled: this.state.disableCodeInput })
          ) : this.state.recovering ? null : _react2.default.createElement(
            'div',
            { className: styles.password },
            _react2.default.createElement(
              'div',
              { className: styles.icons },
              _react2.default.createElement(
                'div',
                { className: styles.inputTitle },
                (0, _translations2.default)('PASSWORD')
              ),
              _react2.default.createElement('input', { type: this.state.passwordType, ref: 'password', placeholder: (0, _translations2.default)('PASSWORD'), autoComplete: 'current-password' })
            ),
            this.props.isMobile ? _react2.default.createElement(md.eye, { size: 20, className: styles.show, color: '#606060', onClick: this.togglePassword.bind(this) }) : null
          )
        ),
        _config2.default.allow2FA && this.state.twoFA ? _react2.default.createElement(
          'p',
          { className: styles.tigoLoginFooter },
          this.state.selectedMethod && _react2.default.createElement(
            'button',
            { className: styles.button, onClick: this.handleSubmitTwoFA.bind(this), disabled: this.state.disableCodeInput },
            (0, _translations2.default)('SEND')
          ),
          this.state.showTwoFAOptions && this.state.selectedMethod && _react2.default.createElement(
            'a',
            { className: styles.recovery + ' ' + styles.twofa, onClick: function onClick() {
                return _this11.handleRequestCode(_this11.state.byEmail);
              } },
            (0, _translations2.default)('REQUEST CODE')
          ),
          this.state.showTwoFAOptions && this.state.selectedMethod && this.state.user.allow_sms_2fa && _react2.default.createElement(
            'a',
            { className: styles.recovery + ' ' + styles.twofa, onClick: function onClick() {
                return _this11.setState({ selectedMethod: false });
              } },
            (0, _translations2.default)('CHANGE DELIVERY METHOD'),
            ' '
          ),
          !this.state.showTwoFAOptions && this.state.selectedMethod && _react2.default.createElement(
            'a',
            { className: styles.recovery + ' ' + styles.twofa },
            (0, _translations2.default)('REQUEST NEW CODE'),
            '  (',
            this.state.timer,
            ')'
          ),
          _react2.default.createElement(
            'a',
            { className: styles.recovery, onClick: this.handleCancelTwoFA.bind(this) },
            (0, _translations2.default)('CANCEL')
          )
        ) : this.state.recovering ? _react2.default.createElement(
          'p',
          { className: styles.tigoLoginFooter },
          _react2.default.createElement(
            'button',
            { className: styles.button, onClick: this.handleSubmitRecovery.bind(this) },
            (0, _translations2.default)('REQUEST RECOVERY')
          ),
          _react2.default.createElement(
            'a',
            { className: styles.recovery, onClick: this.handleRecovery.bind(this) },
            (0, _translations2.default)('CLIENT LOGIN')
          )
        ) : _react2.default.createElement(
          'p',
          { className: styles.tigoLoginFooter },
          this.renderRecaptcha(),
          _react2.default.createElement(
            'button',
            { className: styles.button, onClick: this.handleRecaptcha.bind(this) },
            (0, _translations2.default)('LOGIN')
          ),
          _config2.default.allowSSO && _react2.default.createElement(
            'a',
            { className: styles.recovery + ' ' + styles.sso, onClick: this.handleSSO.bind(this) },
            "Ingresar con SSO"
          ),
          _react2.default.createElement(
            'a',
            { className: styles.recovery, onClick: this.handleRecovery.bind(this) },
            (0, _translations2.default)('RECOVER PASSWORD')
          )
        )
      );
    }
  }, {
    key: 'renderForm',
    value: function renderForm(styles) {
      var _this12 = this;

      var inputStyle = { border: 'solid 1px #8e91a4', fontSize: '1rem', height: '30px', width: '300px' };
      return _react2.default.createElement(
        'div',
        null,
        _config2.default.allow2FA && this.state.twoFA ? this.state.selectedMethod ? this.state.byEmail ? _react2.default.createElement(
          'p',
          { className: styles.twoFaDescription },
          (0, _translations2.default)('TWO FA DESCRIPTION'),
          ' ',
          this.state.username ? this.state.username : ""
        ) : _react2.default.createElement(
          'p',
          { className: styles.twoFaDescription },
          (0, _translations2.default)('TWO FA DESCRIPTION SMS'),
          ' ',
          this.state.phoneNumber
        ) : null : null,
        this.state.message ? _react2.default.createElement(
          'div',
          { className: styles.error, 'data-actualerror': this.props.auth.data },
          _react2.default.createElement(
            'span',
            { className: styles.errortext },
            this.state.message
          )
        ) : null,
        _react2.default.createElement(
          'div',
          null,
          _config2.default.allow2FA && this.state.twoFA ? null : _react2.default.createElement(
            'div',
            { className: styles.username, style: { width: '300px' } },
            _react2.default.createElement(
              'div',
              { className: styles.inputTitle },
              (0, _translations2.default)('EMAIL')
            ),
            _react2.default.createElement('input', { type: 'email', ref: 'username', placeholder: 'ejemplo@correo.com', autoComplete: 'username', style: inputStyle })
          ),
          _config2.default.allow2FA && this.state.twoFA ? !this.state.selectedMethod ? _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(
              'p',
              { className: styles.twoFaDescription },
              (0, _translations2.default)('SELECT METHOD FOR SECURITY CODE'),
              ' '
            ),
            _react2.default.createElement(
              'button',
              { className: styles.button2FA, onClick: function onClick() {
                  return _this12.sendCode(false);
                } },
              _react2.default.createElement('i', { className: styles.icon, dangerouslySetInnerHTML: { __html: icons['sms'] } }),
              ' Mensaje de texto'
            ),
            _react2.default.createElement(
              'button',
              { className: styles.buttonSecondary2FA, onClick: function onClick() {
                  return _this12.sendCode(true);
                } },
              _react2.default.createElement(md.message, { className: styles.icon, size: 25, color: 'white' }),
              ' Correo electr\xF3nico'
            )
          ) : _react2.default.createElement(
            'div',
            { className: styles.code, style: { marginTop: "5px" } },
            _react2.default.createElement('input', { type: 'number', ref: 'code', placeholder: (0, _translations2.default)('2FA CODE'), autoComplete: 'code', disabled: this.state.disableCodeInput })
          ) : this.state.recovering ? null : _react2.default.createElement(
            'div',
            { className: styles.password, style: { width: '300px' } },
            _react2.default.createElement(
              'div',
              { className: styles.inputTitle },
              (0, _translations2.default)('PASSWORD')
            ),
            _react2.default.createElement('input', { type: this.state.passwordType, ref: 'password', autoComplete: 'current-password', style: inputStyle }),
            this.props.isMobile ? _react2.default.createElement(md.eye, { size: 20, className: styles.show, color: '#606060', onClick: this.togglePassword.bind(this) }) : null
          )
        ),
        _config2.default.allow2FA && this.state.twoFA ? _react2.default.createElement(
          'p',
          null,
          this.state.selectedMethod && _react2.default.createElement(
            'button',
            { className: styles.button, onClick: this.handleSubmitTwoFA.bind(this), disabled: this.state.disableCodeInput },
            (0, _translations2.default)('SEND')
          ),
          this.state.showTwoFAOptions && this.state.selectedMethod && _react2.default.createElement(
            'a',
            { className: styles.recovery + ' ' + styles.twofa, onClick: function onClick() {
                return _this12.handleRequestCode(_this12.state.byEmail);
              } },
            (0, _translations2.default)('REQUEST CODE')
          ),
          this.state.showTwoFAOptions && this.state.selectedMethod && this.state.user.allow_sms_2fa && _react2.default.createElement(
            'a',
            { className: styles.recovery + ' ' + styles.twofa, onClick: function onClick() {
                return _this12.setState({ selectedMethod: false });
              } },
            (0, _translations2.default)('CHANGE DELIVERY METHOD'),
            ' '
          ),
          !this.state.showTwoFAOptions && this.state.selectedMethod && _react2.default.createElement(
            'a',
            { className: styles.recovery + ' ' + styles.twofa },
            (0, _translations2.default)('REQUEST NEW CODE'),
            '  (',
            this.state.timer,
            ')'
          ),
          _react2.default.createElement(
            'a',
            { className: styles.recovery, onClick: this.handleCancelTwoFA.bind(this) },
            (0, _translations2.default)('CANCEL')
          )
        ) : this.state.recovering ? _react2.default.createElement(
          'p',
          null,
          _react2.default.createElement(
            'button',
            { className: styles.button, onClick: this.handleSubmitRecovery.bind(this) },
            (0, _translations2.default)('REQUEST RECOVERY')
          ),
          _react2.default.createElement(
            'a',
            { className: styles.recovery, onClick: this.handleRecovery.bind(this) },
            (0, _translations2.default)('CLIENT LOGIN')
          )
        ) : _react2.default.createElement(
          'p',
          null,
          this.renderRecaptcha(),
          _react2.default.createElement(
            'button',
            { className: styles.button, onClick: this.handleRecaptcha.bind(this) },
            (0, _translations2.default)('LOGIN')
          ),
          _config2.default.allowSSO && _react2.default.createElement(
            'a',
            { className: styles.recovery + ' ' + styles.sso, onClick: this.handleSSO.bind(this) },
            "Ingresar con SSO"
          ),
          _react2.default.createElement(
            'a',
            { className: styles.recovery, onClick: this.handleRecovery.bind(this) },
            (0, _translations2.default)('RECOVER PASSWORD')
          )
        )
      );
    }
  }, {
    key: 'renderLoading',
    value: function renderLoading(styles) {
      return _react2.default.createElement(
        'div',
        { className: styles.loading },
        _react2.default.createElement(_components.Loading, null)
      );
    }
  }, {
    key: 'renderSuspendedAdvise',
    value: function renderSuspendedAdvise(styles) {
      var message = this.state.user_not_logged ? (0, _translations2.default)('LOGIN_ERROR') : this.state.user_suspended ? (0, _translations2.default)('USER SUSPENDED ALERT') : this.state.user_temporarily_suspended ? (0, _translations2.default)('USER TEMPORARILY SUSPENDED ALERT') : (0, _translations2.default)('ACCOUNT SUSPENDED ALERT');

      return _react2.default.createElement(_components.CustomSnackbar, {
        messageText: message,
        variant: 'error',
        isOpen: this.state.user_suspended || this.state.account_suspended || this.state.user_temporarily_suspended || this.state.user_not_logged,
        autoHideDuration: this.state.user_not_logged ? 4000 : 8000,
        handleClose: this.cleanLocalStorage
      });
    }
  }, {
    key: 'renderReachLogin',
    value: function renderReachLogin(styles, auth, partner, data, url) {
      var logoReach = require('../../../static/logo-reach.svg');
      return _react2.default.createElement(
        'div',
        { className: styles.login },
        _react2.default.createElement('img', { src: '../../../static/login_left.svg' }),
        _react2.default.createElement(
          'form',
          { className: styles.loginForm },
          _react2.default.createElement('img', { src: '../../../static/logo-reach-sky.svg', style: { marginTop: '25px' } }),
          auth.loading ? this.renderLoading(styles) : data.logged_in && !(_config2.default.allow2FA && this.state.twoFA) ? _react2.default.createElement(
            'div',
            { className: styles.Logged },
            _react2.default.createElement(
              'div',
              { className: styles.detail },
              (0, _translations2.default)('USER ACCOUNT'),
              ' '
            ),
            ' ',
            _react2.default.createElement(
              'div',
              null,
              this.props.auth.current_account.name
            ),
            _react2.default.createElement(
              'div',
              { className: styles.detail },
              (0, _translations2.default)('USER USERNAME'),
              ' '
            ),
            ' ',
            _react2.default.createElement(
              'div',
              null,
              ' ',
              data.user.username
            ),
            _react2.default.createElement(
              _reactRouter.Link,
              { to: url, className: styles.goto },
              (0, _translations2.default)('LOGIN SITE')
            ),
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'button',
                { onClick: this.handleLogout.bind(this), className: styles.buttonLogout },
                (0, _translations2.default)('LOGOUT')
              )
            )
          ) : this.renderForm(styles),
          _react2.default.createElement(
            'div',
            { className: styles.loginCard___help },
            _react2.default.createElement(
              'h3',
              { style: { cursor: 'auto' } },
              '\xBFNecesitas ayuda?',
              _react2.default.createElement(
                'strong',
                { className: styles.loginCard___helpContact, onClick: function onClick() {
                    return window.open('https://wa.me/' + process.env.REACT_APP_SUPPORT_PHONE, "_blank");
                  } },
                'Escr\xEDbenos aqu\xED'
              )
            ),
            _react2.default.createElement('img', { src: '../../../static/login-help.svg', alt: '', style: { marginLeft: '3px' } })
          )
        ),
        _react2.default.createElement('img', { src: '../../../static/login_right.svg' }),
        this.renderSuspendedAdvise(styles)
      );
    }
  }, {
    key: 'renderQuicklyLogin',
    value: function renderQuicklyLogin(styles, auth, partner, data, url) {
      var logoQuickly = require('../../../static/quickly/quickly.svg');
      return _react2.default.createElement(
        'div',
        { className: styles.login },
        _react2.default.createElement('img', { src: '../../../static/login_left.svg' }),
        _react2.default.createElement(
          'form',
          { className: styles.loginForm },
          _react2.default.createElement('div', { className: styles.logoQuickly, dangerouslySetInnerHTML: { __html: logoQuickly } }),
          auth.loading ? this.renderLoading(styles) : data.logged_in && !(_config2.default.allow2FA && this.state.twoFA) ? _react2.default.createElement(
            'div',
            { className: styles.Logged },
            _react2.default.createElement(
              'div',
              { className: styles.detail },
              (0, _translations2.default)('USER ACCOUNT'),
              ' '
            ),
            ' ',
            _react2.default.createElement(
              'div',
              null,
              this.props.auth.current_account.name
            ),
            _react2.default.createElement(
              'div',
              { className: styles.detail },
              (0, _translations2.default)('USER USERNAME'),
              ' '
            ),
            ' ',
            _react2.default.createElement(
              'div',
              null,
              ' ',
              data.user.username
            ),
            _react2.default.createElement(
              _reactRouter.Link,
              { to: url, className: styles.goto },
              (0, _translations2.default)('LOGIN SITE')
            ),
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'button',
                { onClick: this.handleLogout.bind(this), className: styles.buttonLogout },
                (0, _translations2.default)('LOGOUT')
              )
            )
          ) : this.renderForm(styles),
          _react2.default.createElement(
            'div',
            { className: styles.loginCard___help },
            _react2.default.createElement(
              'h3',
              { style: { cursor: 'auto' } },
              '\xBFNecesitas ayuda?',
              _react2.default.createElement(
                'strong',
                { className: styles.loginCard___helpContact, onClick: function onClick() {
                    return window.open('https://wa.me/' + process.env.REACT_APP_SUPPORT_PHONE, "_blank");
                  } },
                'Escr\xEDbenos aqu\xED'
              )
            ),
            _react2.default.createElement('img', { src: '../../../static/login-help.svg', alt: '', style: { marginLeft: '3px' } })
          )
        ),
        _react2.default.createElement('img', { src: '../../../static/login_right.svg' }),
        this.renderSuspendedAdvise(styles)
      );
    }
  }, {
    key: 'renderTigoLogin',
    value: function renderTigoLogin(styles, auth, partner, data, url) {
      var partnerBackground = './static/tigo/tigo_background.webp';
      var tigoLogo = './static/tigo/tigo.svg';
      return _react2.default.createElement(
        'div',
        { className: styles.tigoLogin, style: { backgroundImage: 'url(' + partnerBackground + ')' } },
        _react2.default.createElement(
          'div',
          { className: styles.tigoLoginCard },
          _react2.default.createElement('img', { src: tigoLogo, className: styles.tigoLogo }),
          this.renderTigoForm(styles)
        ),
        this.renderSuspendedAdvise(styles)
      );
    }
  }, {
    key: 'renderClaroLogin',
    value: function renderClaroLogin(stylesNotificame, auth, data, url) {
      var partnerLogo = './static/notificame/logo-gris.svg';
      var partnerBackground = './static/notificame/bg.jpeg';
      var partnerLogoRed = './static/notificame/logo-rojo.svg';
      return _react2.default.createElement(
        'div',
        { className: stylesNotificame.login },
        _react2.default.createElement(
          'div',
          { className: stylesNotificame.headerNoti },
          _react2.default.createElement('img', { className: stylesNotificame.partnerLogoRed, src: partnerLogoRed })
        ),
        _react2.default.createElement(
          'div',
          { className: stylesNotificame.container, style: { backgroundImage: 'url(' + partnerBackground + ')', backgroundSize: 'cover' } },
          _react2.default.createElement(
            'div',
            { className: stylesNotificame.leftAside },
            _react2.default.createElement('img', { className: stylesNotificame.partnerLogo, src: partnerLogo }),
            _react2.default.createElement(
              'form',
              { className: stylesNotificame.loginForm },
              auth.loading ? _react2.default.createElement(
                'font',
                { className: stylesNotificame.title },
                (0, _translations2.default)('loading'),
                '...'
              ) : data.logged_in && !(_config2.default.allow2FA && this.state.twoFA) ? _react2.default.createElement(
                'div',
                { className: stylesNotificame.Logged },
                _react2.default.createElement(
                  'div',
                  { className: stylesNotificame.detail },
                  (0, _translations2.default)('USER ACCOUNT'),
                  ' '
                ),
                ' ',
                _react2.default.createElement(
                  'div',
                  null,
                  this.props.auth.current_account.name
                ),
                _react2.default.createElement(
                  'div',
                  { className: stylesNotificame.detail },
                  (0, _translations2.default)('USER USERNAME'),
                  ' '
                ),
                ' ',
                _react2.default.createElement(
                  'div',
                  null,
                  ' ',
                  data.user.username
                ),
                _react2.default.createElement(
                  _reactRouter.Link,
                  { to: url, className: stylesNotificame.goto },
                  (0, _translations2.default)('LOGIN SITE')
                ),
                _react2.default.createElement(
                  'div',
                  null,
                  _react2.default.createElement(
                    'button',
                    { onClick: this.handleLogout.bind(this), className: stylesNotificame.buttonLogout },
                    (0, _translations2.default)('LOGOUT')
                  )
                )
              ) : this.renderFormNotificame(stylesNotificame)
            ),
            this.renderSuspendedAdvise(stylesNotificame),
            _react2.default.createElement(
              'div',
              { className: stylesNotificame.copyRights },
              (0, _translations2.default)('LOGIN COPY RIGHTS')
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: stylesNotificame.formTwo },
          _react2.default.createElement(
            'p',
            null,
            ' ',
            _react2.default.createElement(
              'a',
              { target: '_blank', href: 'http://ubiquo.io/' },
              ' ',
              _react2.default.createElement(
                'button',
                { className: stylesNotificame.buttonTwo },
                ' Descubre el nuevo Toolkit '
              ),
              ' '
            ),
            ' '
          )
        )
      );
    }
  }, {
    key: 'renderRecaptcha',
    value: function renderRecaptcha() {
      return _react2.default.createElement(_reactGoogleRecaptcha2.default, {
        ref: recaptchaRef,
        sitekey: process.env.REACT_APP_RECAPTCHA_KEY,
        onChange: this.onChange.bind(this),
        onErrored: this.onErrored,
        size: 'invisible'
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var styles = require('./Login.scss');
      var stylesNotificame = require('./LoginNotificame.scss');
      var tigoStyles = require('./Tigo.scss');
      var _quicklyStyles = require('./LoginQuickly.scss');
      var quicklyStyles = (0, _extends3.default)({}, styles, _quicklyStyles);
      var _props = this.props,
          auth = _props.auth,
          data = _props.auth.data;

      var current_account = void 0;
      var url = "/dashboard";
      var partner = (0, _partner.getPartnerByHost)() || this.props.location.query.p;
      if (this.props.accounts) {
        current_account = this.props.accounts.find(function (a) {
          return a.is_active;
        });
        if (current_account) {
          if (current_account.partner) {
            partner = current_account.partner;
          }

          if (current_account.account_type == "Reseller") {
            url = "/reseller";
          }
          if (current_account.roles.indexOf('staff') > -1) {
            url = "/staff/messages";
          }
        }
      }
      if ((0, _partner.partnerByHost)() == 'tigo') {
        return this.renderTigoLogin(tigoStyles, auth, data, url);
      } else if ((0, _partner.partnerByHost)() == 'claro') {
        return this.renderClaroLogin(stylesNotificame, auth, data, url);
      } else if ((0, _partner.partnerByHost)() == 'quickly') {
        return this.renderQuicklyLogin(quicklyStyles, auth, data, url);
      } else {
        return this.renderReachLogin(styles, auth, partner, data, url);
      }
    }
  }]);
  return Login;
}(_react2.default.Component), _class2.propTypes = {
  auth: _propTypes2.default.object,
  isMobile: _propTypes2.default.bool,
  login: _propTypes2.default.func,
  passwordRecover: _propTypes2.default.func,
  logout: _propTypes2.default.func,
  pushState: _propTypes2.default.func,
  twoFaToken: _propTypes2.default.func,
  twoFaTokenVerify: _propTypes2.default.func
}, _temp)) || _class) || _class);
exports.default = Login;
module.exports = exports['default'];