'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomPieChart = exports.GenericBarChart = exports.GenericLineChart = exports.GenericPieChart = exports.StockLine = exports.TagCloudChart = exports.AreaChart = exports.NpsChart = exports.PieChart = undefined;

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _class, _temp, _class2, _temp2, _class3, _temp3, _class4, _temp4, _class5, _temp5, _class6, _temp6, _class7, _temp7, _class8, _temp8, _class9, _temp9;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactHighcharts = require('react-highcharts');

var _reactHighcharts2 = _interopRequireDefault(_reactHighcharts);

var _ReactHighstock = require('react-highcharts/ReactHighstock.src');

var _ReactHighstock2 = _interopRequireDefault(_ReactHighstock);

var _reactTagcloud = require('react-tagcloud');

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PieChart = exports.PieChart = (_temp = _class = function (_React$Component) {
  (0, _inherits3.default)(PieChart, _React$Component);

  function PieChart() {
    (0, _classCallCheck3.default)(this, PieChart);
    return (0, _possibleConstructorReturn3.default)(this, (PieChart.__proto__ || (0, _getPrototypeOf2.default)(PieChart)).apply(this, arguments));
  }

  (0, _createClass3.default)(PieChart, [{
    key: 'renderOptions',
    value: function renderOptions(options) {
      var _this2 = this;

      var style = require('./Charts.scss');
      var values = options.map(function (s, index) {
        var color = _this2.props.colors[index % _this2.props.colors.length];
        return _react2.default.createElement(
          'tr',
          { key: _this2.props.question.content + '_tr_' + index },
          _react2.default.createElement(
            'td',
            { className: style.yaxis },
            s.y
          ),
          _react2.default.createElement(
            'td',
            { className: style.xaxis },
            _react2.default.createElement(
              'div',
              { className: style.contain, title: s.name },
              _react2.default.createElement('span', { className: style.legendBullet, style: { background: color, borderColor: color } }),
              s.name
            )
          )
        );
      });
      return _react2.default.createElement(
        'div',
        { className: style.column + ' ' + style.options },
        _react2.default.createElement(
          'table',
          null,
          _react2.default.createElement(
            'tbody',
            null,
            values
          )
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var style = require('./Charts.scss');
      var options = this.props.question.stats.options || this.props.question.stats.values;
      var series = options ? options.map(function (s) {
        return {
          name: s.text,
          y: s.total,
          pct: s.percent
        };
      }) : [];

      var percentage = '{point.percentage:.1f}';
      if (this.props.plotType == 'column') {
        percentage = '{point.pct:.1f}';
      }

      var i = void 0;
      var showGraph = false;
      for (i = 0; i < series.length; i++) {
        var serie = series[i];
        if (serie.y > 0) {
          showGraph = true;
          break;
        }
      }
      var config = {
        colors: this.props.colors,
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: this.props.plotType,
          height: this.props.size || 150,
          width: this.props.size || 150,
          style: { margin: '0 auto' }
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>' + percentage + '%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
              format: '<b>{point.name}</b>: {y}',
              style: {
                color: 'black'
              }
            },
            showInLegend: true
          },

          column: {
            pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0,
            shadow: false
          }
        },
        legend: {
          enabled: false,
          layout: 'vertical',
          align: 'left',
          width: 200,
          verticalAlign: 'top',
          // floating: true,
          backgroundColor: '#FFFFFF'
        },
        series: [{
          name: 'count',
          colorByPoint: true,
          data: series
          // showInLegend: false
        }],
        credits: {
          enabled: false
        }
      };
      return _react2.default.createElement(
        'div',
        { className: style.container },
        this.props.showOptions ? this.renderOptions(series) : null,
        _react2.default.createElement(
          'div',
          { className: style.column + ' ' + style.graph },
          showGraph ? _react2.default.createElement(_reactHighcharts2.default, { className: style.chart, config: config }) : _react2.default.createElement(
            'p',
            { className: style.resultsP },
            (0, _translations2.default)('No results yet')
          )
        )
      );
    }
  }]);
  return PieChart;
}(_react2.default.Component), _class.propTypes = {
  question: _propTypes2.default.object.isRequired,
  showOptions: _propTypes2.default.bool.isRequired,
  plotType: _propTypes2.default.string.isRequired,
  colors: _propTypes2.default.array.isRequired
}, _class.defaultProps = {
  showOptions: true,
  colors: ['#86D0FA', '#2EB0F6', '#139CF4', '#0771AB', '#599ABD', '#033A58', '#C7EBFF']
}, _temp);
var NpsChart = exports.NpsChart = (_temp2 = _class2 = function (_React$Component2) {
  (0, _inherits3.default)(NpsChart, _React$Component2);

  function NpsChart() {
    (0, _classCallCheck3.default)(this, NpsChart);
    return (0, _possibleConstructorReturn3.default)(this, (NpsChart.__proto__ || (0, _getPrototypeOf2.default)(NpsChart)).apply(this, arguments));
  }

  (0, _createClass3.default)(NpsChart, [{
    key: 'render',
    value: function render() {
      var style = require('./Charts.scss');
      var options = this.props.question.stats.options || this.props.question.stats.values;
      var series = options ? options.map(function (s) {
        return {
          name: s.text,
          y: s.total,
          pct: s.percent
        };
      }) : [];
      if (this.props.plotType == 'column') {
        percentage = '{point.pct:.1f}';
      }

      var detractorsNum = series[1].y + series[2].y + series[3].y + series[4].y + series[5].y + series[6].y;
      var pasivesNum = series[7].y + series[8].y;
      var promotersNum = series[9].y + series[10].y;
      var total = detractorsNum + pasivesNum + promotersNum;

      var detractorsPer = detractorsNum * 100 / total;
      var pasivesPer = pasivesNum * 100 / total;
      var promotersPer = promotersNum * 100 / total;

      var NPS = (promotersPer - detractorsPer).toFixed(0);

      var config = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: this.props.size || 250
        },
        colors: ['#DD4E65', '#F8C83A', '#57B481'],
        title: {
          text: NPS,
          align: 'center',
          verticalAlign: 'top',
          y: 135,
          style: {
            fontSize: 60,
            fontWeight: 'bold'
          }
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false
            },
            size: '100%'
          }
        },
        series: [{
          type: 'pie',
          name: (0, _translations2.default)('PERCENTAGE'),
          innerSize: '70%',
          data: [[(0, _translations2.default)('DETRACTORS'), detractorsPer], [(0, _translations2.default)('PASSIVES'), pasivesPer], [(0, _translations2.default)('PROMOTERS'), promotersPer]]
        }],
        credits: {
          enabled: false
        }
      };

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_reactHighcharts2.default, { className: style.chart, config: config }),
        _react2.default.createElement(
          'div',
          { className: style.gridNPS },
          _react2.default.createElement(
            'div',
            { className: style.score },
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'div',
                { className: style.detractors },
                ' ',
                (0, _translations2.default)('DETRACTORS'),
                ' '
              ),
              _react2.default.createElement(
                'div',
                { className: style.pasives },
                ' ',
                (0, _translations2.default)('PASSIVES'),
                ' '
              ),
              _react2.default.createElement(
                'div',
                { className: style.promoters },
                ' ',
                (0, _translations2.default)('PROMOTERS'),
                ' '
              ),
              _react2.default.createElement(
                'div',
                { className: style.participants },
                ' TOTAL '
              )
            ),
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'div',
                { className: style.detractors },
                ' ',
                detractorsNum
              ),
              _react2.default.createElement(
                'div',
                { className: style.pasives },
                ' ',
                pasivesNum,
                ' '
              ),
              _react2.default.createElement(
                'div',
                { className: style.promoters },
                ' ',
                promotersNum,
                ' '
              ),
              _react2.default.createElement(
                'div',
                { className: style.participants },
                ' ',
                total,
                ' '
              )
            ),
            _react2.default.createElement(
              'div',
              null,
              _react2.default.createElement(
                'div',
                { className: style.detractors },
                ' ',
                detractorsPer.toFixed(1),
                '%'
              ),
              _react2.default.createElement(
                'div',
                { className: style.pasives },
                ' ',
                pasivesPer.toFixed(1),
                '% '
              ),
              _react2.default.createElement(
                'div',
                { className: style.promoters },
                ' ',
                promotersPer.toFixed(1),
                '% '
              )
            )
          )
        ),
        _react2.default.createElement(
          'div',
          { className: style.formula },
          ' ',
          _react2.default.createElement(
            'span',
            null,
            'NPS ='
          ),
          ' ',
          _react2.default.createElement(
            'span',
            { className: style.promoters },
            ' %',
            (0, _translations2.default)('PROMOTERS')
          ),
          ' \u2014 ',
          _react2.default.createElement(
            'span',
            { className: style.detractors },
            ' %',
            (0, _translations2.default)('DETRACTORS')
          ),
          ' '
        )
      );
    }
  }]);
  return NpsChart;
}(_react2.default.Component), _class2.propTypes = {
  question: _propTypes2.default.object.isRequired,
  showOptions: _propTypes2.default.bool.isRequired,
  plotType: _propTypes2.default.string.isRequired,
  colors: _propTypes2.default.array.isRequired
}, _temp2);
var AreaChart = exports.AreaChart = (_temp3 = _class3 = function (_React$Component3) {
  (0, _inherits3.default)(AreaChart, _React$Component3);

  function AreaChart() {
    (0, _classCallCheck3.default)(this, AreaChart);
    return (0, _possibleConstructorReturn3.default)(this, (AreaChart.__proto__ || (0, _getPrototypeOf2.default)(AreaChart)).apply(this, arguments));
  }

  (0, _createClass3.default)(AreaChart, [{
    key: 'renderOptions',
    value: function renderOptions(options) {
      var _this5 = this;

      var style = require('./Charts.scss');
      var values = options.sort(function (a, b) {
        return b.y - a.y;
      }).slice(0, 10).map(function (s, index) {
        return _react2.default.createElement(
          'tr',
          { key: _this5.props.question.content + '_tr_' + index },
          _react2.default.createElement(
            'td',
            { className: style.yaxis },
            s.y
          ),
          _react2.default.createElement(
            'td',
            { className: style.xaxis },
            s.name
          )
        );
      });
      // <div className={style.tableTitle}>Resumen</div>
      return _react2.default.createElement(
        'div',
        { className: style.column + ' ' + style.options },
        _react2.default.createElement(
          'table',
          null,
          _react2.default.createElement(
            'tbody',
            null,
            values
          )
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var style = require('./Charts.scss');
      var options = this.props.question.stats.values;
      var series = options ? options.map(function (s) {
        return {
          name: s.text,
          y: s.total
        };
      }) : [];
      var data = options ? options.map(function (s) {
        return s.total;
      }) : [];
      var showGraph = true;
      var config = {
        colors: ['#86D0FA', '#2EB0F6', '#139CF4', '#0771AB', '#599ABD', '#033A58', '#C7EBFF'],
        chart: {
          /* plotBackgroundColor: null,
          plotBorderWidth: null, */
          plotShadow: false,
          type: 'area',
          width: 100,
          style: { margin: '0 auto' }
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}</b>'
        },
        plotOptions: {
          area: {
            allowPointSelect: true,
            cursor: 'pointer',
            showInLegend: true
          }

        },
        legend: {
          enabled: false
        },
        series: [{
          name: 'count',
          data: data
        }],
        credits: {
          enabled: false
        }
      };
      return _react2.default.createElement(
        'div',
        { className: style.container },
        _react2.default.createElement(
          'div',
          { className: style.column + ' ' + style.graph },
          showGraph ? _react2.default.createElement(_reactHighcharts2.default, { className: style.chart, config: config }) : _react2.default.createElement(
            'p',
            null,
            (0, _translations2.default)('No results yet')
          )
        ),
        this.props.showOptions ? this.renderOptions(series) : null
      );
    }
  }]);
  return AreaChart;
}(_react2.default.Component), _class3.propTypes = {
  question: _propTypes2.default.object.isRequired,
  showOptions: _propTypes2.default.bool.isRequired,
  plotType: _propTypes2.default.string.isRequired
}, _class3.defaultProps = {
  showOptions: true
}, _temp3);
var TagCloudChart = exports.TagCloudChart = (_temp4 = _class4 = function (_React$Component4) {
  (0, _inherits3.default)(TagCloudChart, _React$Component4);

  function TagCloudChart() {
    (0, _classCallCheck3.default)(this, TagCloudChart);
    return (0, _possibleConstructorReturn3.default)(this, (TagCloudChart.__proto__ || (0, _getPrototypeOf2.default)(TagCloudChart)).apply(this, arguments));
  }

  (0, _createClass3.default)(TagCloudChart, [{
    key: 'renderOptions',
    value: function renderOptions(options) {
      var _this7 = this;

      var style = require('./Charts.scss');
      var values = options.sort(function (a, b) {
        return b.count - a.count;
      }).slice(0, 10).map(function (s, index) {
        return _react2.default.createElement(
          'tr',
          { key: _this7.props.question.content + '_tr_' + index },
          _react2.default.createElement(
            'td',
            { className: style.yaxis },
            s.count
          ),
          _react2.default.createElement(
            'td',
            { className: style.xaxis },
            s.value
          )
        );
      });
      if (options.length > 0) {
        return _react2.default.createElement(
          'div',
          { className: style.column },
          _react2.default.createElement(
            'div',
            { className: style.tableTitle },
            'Resumen'
          ),
          _react2.default.createElement(
            'table',
            null,
            _react2.default.createElement(
              'tbody',
              null,
              values
            )
          )
        );
      } else {
        return _react2.default.createElement(
          'div',
          { className: style.tableTitle },
          (0, _translations2.default)('NO RESULTS YET')
        );
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var style = require('./Charts.scss');
      var firstResults = this.props.question.stats.values.sort(function (a, b) {
        return a.total - b.total;
      }).slice(0, 100).map(function (s) {
        return {
          value: s.text,
          count: s.total
        };
      });
      return _react2.default.createElement(
        'div',
        { className: style.container },
        _react2.default.createElement(
          'div',
          { className: style.column + ' ' + style.graph + ' ' + style.options },
          _react2.default.createElement(_reactTagcloud.TagCloud, { className: style.tag, minSize: 8, maxSize: 20, tags: firstResults })
        )
      );
    }
  }]);
  return TagCloudChart;
}(_react2.default.Component), _class4.propTypes = {
  question: _propTypes2.default.object.isRequired
}, _temp4);
var StockLine = exports.StockLine = (_temp5 = _class5 = function (_React$Component5) {
  (0, _inherits3.default)(StockLine, _React$Component5);

  function StockLine() {
    (0, _classCallCheck3.default)(this, StockLine);
    return (0, _possibleConstructorReturn3.default)(this, (StockLine.__proto__ || (0, _getPrototypeOf2.default)(StockLine)).apply(this, arguments));
  }

  (0, _createClass3.default)(StockLine, [{
    key: 'render',
    value: function render() {
      var data = this.props.url_stats.stats;
      var data_unique_visits = this.props.url_stats.values_for_graphic_unique_visists;
      var tags = this.props.url_stats.tags || [];
      var hoy = new Date();
      var tuNuevosDatos = [[Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 6), 10], [Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 5), 15], [Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 4), 12], [Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 3), 18], [Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 2), 16], [Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - 1), 20], [Date.UTC(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()), 22]];

      var config = {
        credits: {
          enabled: false
        },
        rangeSelector: {
          buttons: [{
            type: 'day',
            count: 7,
            text: '7D'
          }, {
            type: 'day',
            count: 30,
            text: '30D'
          }, {
            type: 'day',
            count: 90,
            text: '90D'
          }, {
            type: 'all',
            count: 1,
            text: 'Todo'
          }],
          selected: 0,
          inputEnabled: false
        },
        title: {
          text: 'Visitas'
        },
        navigator: {
          enabled: true,
          series: [{
            type: 'area',
            color: '#FE0000',
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [[0, "rgba(254,0,0,0.05)"], [1, "rgba(254,0,0,0)"]]
            },
            dataGrouping: {
              smoothed: true
            },
            lineWidth: 1,
            marker: {
              enabled: false
            }
          }, {
            type: 'area',
            color: '#7cb5ec',
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [[0, "rgba(124,181,236,0.05)"], [1, "rgba(124,181,236,0)"]]
            },
            dataGrouping: {
              smoothed: true
            },
            lineWidth: 1,
            marker: {
              enabled: false
            }
          }],
          xAxis: {
            labels: {
              enabled: false
            }
          }
        },
        series: [{
          name: (0, _translations2.default)('UNIQUE VISITS'),
          type: 'area',
          data: data_unique_visits,
          color: '#808080',
          lineWidth: 2,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, "#808080"], [1, "rgba(128,128,128,0)"]]
          },
          showInNavigator: true
        }, {
          name: 'número de visitas',
          id: 'dataseries',
          type: 'area',
          data: data,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [[0, "#7cb5ec"], [1, "rgba(124,181,236,0)"]]
          },
          showInNavigator: true
        }, {
          type: 'flags',
          data: tags,
          //onSeries: 'dataseries',
          shape: 'square'
        }]
      };
      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_ReactHighstock2.default, { config: config })
      );
    }
  }]);
  return StockLine;
}(_react2.default.Component), _class5.propTypes = {
  url_stats: _propTypes2.default.object.isRequired
}, _temp5);
var GenericPieChart = exports.GenericPieChart = (_temp6 = _class6 = function (_React$Component6) {
  (0, _inherits3.default)(GenericPieChart, _React$Component6);

  function GenericPieChart() {
    (0, _classCallCheck3.default)(this, GenericPieChart);
    return (0, _possibleConstructorReturn3.default)(this, (GenericPieChart.__proto__ || (0, _getPrototypeOf2.default)(GenericPieChart)).apply(this, arguments));
  }

  (0, _createClass3.default)(GenericPieChart, [{
    key: 'render',
    value: function render() {
      var data = this.props.url_stats.stats;
      var name = this.props.url_stats.name;

      var config = {
        credits: {
          enabled: false
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: name
        },
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            colors: ["rgb(15,72,127)", "rgb(52,109,164)", "rgb(88,145,200)", "rgb(124,181,236)", "rgb(160,217,255)", "rgb(196,253,255)", "rgb(233,255,255)", "rgb(255,255,255)", "rgb(255,255,255)", "rgb(255,255,255)", "rgb(255,255,255)", "rgb(255,255,255)", "rgb(255,255,255)", "rgb(255,255,255)", "rgb(255,255,255)"],
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.percentage:.1f} %'
            },
            showInLegend: true
          }
        },
        series: [{
          name: name,
          colorByPoint: true,
          data: data
        }]
      };

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_reactHighcharts2.default, { config: config })
      );
    }
  }]);
  return GenericPieChart;
}(_react2.default.Component), _class6.propTypes = {
  url_stats: _propTypes2.default.object.isRequired
}, _temp6);
var GenericLineChart = exports.GenericLineChart = (_temp7 = _class7 = function (_React$Component7) {
  (0, _inherits3.default)(GenericLineChart, _React$Component7);

  function GenericLineChart() {
    (0, _classCallCheck3.default)(this, GenericLineChart);
    return (0, _possibleConstructorReturn3.default)(this, (GenericLineChart.__proto__ || (0, _getPrototypeOf2.default)(GenericLineChart)).apply(this, arguments));
  }

  (0, _createClass3.default)(GenericLineChart, [{
    key: 'render',
    value: function render() {
      var series = this.props.series;
      var height = this.props.height;

      var config = {
        "plotOptions": {
          "series": {
            "animation": false
          }
        },
        "credits": {
          "enabled": false
        },
        "xAxis": [{
          "type": "datetime"
        }],
        "yAxis": [{
          "title": {
            "text": "Cantidad"
          }
        }],
        "title": {
          "text": ""
        },
        "chart": {
          "height": height
        },
        "series": series,
        "style": {
          "color": '#efefef'
        },
        "itemStyle": {
          "color": '#efefef'
        }
      };

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_reactHighcharts2.default, { config: config, isPureConfig: true })
      );
    }
  }]);
  return GenericLineChart;
}(_react2.default.Component), _class7.propTypes = {
  series: _propTypes2.default.array.isRequired
}, _temp7);
var GenericBarChart = exports.GenericBarChart = (_temp8 = _class8 = function (_React$Component8) {
  (0, _inherits3.default)(GenericBarChart, _React$Component8);

  function GenericBarChart() {
    (0, _classCallCheck3.default)(this, GenericBarChart);
    return (0, _possibleConstructorReturn3.default)(this, (GenericBarChart.__proto__ || (0, _getPrototypeOf2.default)(GenericBarChart)).apply(this, arguments));
  }

  (0, _createClass3.default)(GenericBarChart, [{
    key: 'render',
    value: function render() {
      _reactHighcharts2.default.Highcharts.setOptions({ lang: { thousandsSep: ',' } });
      var series = this.props.series;
      var height = this.props.height;
      var title = this.props.title;
      var title_y = this.props.titley;
      var title_x = this.props.titlex;
      var tooltip = this.props.tooltip;

      var config = {
        "credits": {
          "enabled": false
        },
        "chart": {
          "type": 'column',
          "height": height
        },
        "xAxis": {
          "type": 'category',
          "title": {
            "text": title_x
          }
        },
        "title": {
          "text": title,
          "align": 'right',
          "style": {
            "color": '#1ca4fc',
            "fontWeight": 'bold'
          }
        },
        "tooltip": {
          "enabled": tooltip
        },
        "yAxis": [{
          "title": {
            "text": title_y
          }
        }],
        "series": series
      };

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_reactHighcharts2.default, { config: config })
      );
    }
  }]);
  return GenericBarChart;
}(_react2.default.Component), _class8.propTypes = {
  series: _propTypes2.default.array.isRequired
}, _temp8);
var CustomPieChart = exports.CustomPieChart = (_temp9 = _class9 = function (_React$Component9) {
  (0, _inherits3.default)(CustomPieChart, _React$Component9);

  function CustomPieChart() {
    (0, _classCallCheck3.default)(this, CustomPieChart);
    return (0, _possibleConstructorReturn3.default)(this, (CustomPieChart.__proto__ || (0, _getPrototypeOf2.default)(CustomPieChart)).apply(this, arguments));
  }

  (0, _createClass3.default)(CustomPieChart, [{
    key: 'render',
    value: function render() {
      var series = this.props.series;
      var name = this.props.name;
      var tooltip = this.props.tooltip;
      var labels = this.props.labels;
      var height = this.props.height;

      var config = {
        credits: {
          enabled: false
        },
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          height: height,
          type: 'pie'
        },
        title: {
          text: name
        },
        tooltip: {
          pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: labels,
              format: '{point.percentage:.1f} %'
            },
            showInLegend: labels
          }
        },
        series: series
      };

      return _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(_reactHighcharts2.default, { config: config })
      );
    }
  }]);
  return CustomPieChart;
}(_react2.default.Component), _class9.propTypes = {
  series: _propTypes2.default.array.isRequired
}, _temp9);