'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _getOwnPropertyDescriptor = require('babel-runtime/core-js/object/get-own-property-descriptor');

var _getOwnPropertyDescriptor2 = _interopRequireDefault(_getOwnPropertyDescriptor);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _class, _desc, _value, _class2;

var _components = require('components');

var _messages = require('redux/modules/messages');

var _schedulerTasks = require('../../redux/modules/schedulerTasks');

var _tags = require('redux/modules/tags');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _coreDecorators = require('core-decorators');

var _reactRedux = require('react-redux');

var _files = require('redux/modules/files');

var _themeEnabled = require('helpers/themeEnabled');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reachHistory = require('../../helpers/reachHistory');

var _Summary = require('./Summary.scss');

var _Summary2 = _interopRequireDefault(_Summary);

var _lodash = require('lodash');

var _getTimeFromMinutes = require('../../helpers/getTimeFromMinutes');

var _getTimeFromMinutes2 = _interopRequireDefault(_getTimeFromMinutes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
  var desc = {};
  Object['ke' + 'ys'](descriptor).forEach(function (key) {
    desc[key] = descriptor[key];
  });
  desc.enumerable = !!desc.enumerable;
  desc.configurable = !!desc.configurable;

  if ('value' in desc || desc.initializer) {
    desc.writable = true;
  }

  desc = decorators.slice().reverse().reduce(function (desc, decorator) {
    return decorator(target, property, desc) || desc;
  }, desc);

  if (context && desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
    desc.initializer = undefined;
  }

  if (desc.initializer === void 0) {
    Object['define' + 'Property'](target, property, desc);
    desc = null;
  }

  return desc;
}

//todo change filename to Summary
var md = {
  settings: require('react-icons/lib/md/more-vert')
};
var SCHEDULER_OFFSET = process.env.REACT_APP_SCHEDULER_OFFSET || 10;
var ScheduleWizardSummary = (_dec = (0, _reactRedux.connect)(function (state) {
  return {
    account: state.account.data,
    user: state.auth.data.user
  };
}, {
  newTask: _schedulerTasks.newTask,
  getFileStats: _messages.getFileStats,
  getMessageFileDetail: _files.getMessageFileDetail,
  push: _reachHistory.push,
  messageConfirmSendFile: _messages.messageConfirmSendFile,
  incrementTagFileCount: _tags.incrementTagFileCount
}), _dec(_class = (_class2 = function (_React$Component) {
  (0, _inherits3.default)(ScheduleWizardSummary, _React$Component);

  function ScheduleWizardSummary(props) {
    (0, _classCallCheck3.default)(this, ScheduleWizardSummary);

    var _this = (0, _possibleConstructorReturn3.default)(this, (ScheduleWizardSummary.__proto__ || (0, _getPrototypeOf2.default)(ScheduleWizardSummary)).call(this, props));

    if (_this.props.data) {
      var taskData = _this.props.data;
      var contactsClean = [];
      var order = [];
      if (taskData.contacts) {
        taskData.contacts.map(function (value) {
          (0, _keys2.default)(value).map(function (val) {
            if (typeof value !== 'string') {
              if (order.indexOf(value) === -1) {
                order.push(value);
                contactsClean.push({
                  descripcion: (0, _values2.default)(value)[0],
                  value: (0, _values2.default)(value)[0]
                });
              }
            } else if (order.indexOf(value) === -1) {
              order.push(value);
              contactsClean.push({
                descripcion: value,
                value: value
              });
            }
          });
        });
      } else if (taskData.tagsList) {
        contactsClean = taskData.tagsList.map(function (value) {
          var extractValue = (0, _values2.default)(value)[0];
          var valor = extractValue.value_item;
          var newValue = {
            descripcion: extractValue.value_item + ' (' + extractValue.count + ')',
            value: extractValue.value_item
          };
          return newValue;
        });
      }

      var calculatedOffset = taskData.fileStats && taskData.fileStats.estimated_processing_min > SCHEDULER_OFFSET ? taskData.fileStats.estimated_processing_min + 5 : SCHEDULER_OFFSET;

      _this.state = {
        name: _this.props.data.name || '',
        message: _this.props.data.message,
        contacts: contactsClean,
        events: _this.props.data.events,
        form: _this.props.data.form || null,
        isCustom: _this.props.data.customMessage || false,
        tagsList: _this.props.data.tagsList || null,
        displayEvents: [],
        fileUploadResult: _this.props.data.fileUploadResult,
        fileStats: _this.props.data.fileStats,
        file_columns: _this.props.data.file_columns,
        customMessage: _this.props.data.customMessage,
        trackable_urls: _this.props.data.trackable_urls || false,
        tags: _this.props.data.tags || [],
        corruptEvents: false,
        _SCHEDULER_OFFSET: calculatedOffset
      };
    }
    return _this;
  }

  (0, _createClass3.default)(ScheduleWizardSummary, [{
    key: 'validateArray',
    value: function validateArray(array) {
      var result = false;
      if (array) {
        if (Array.isArray(array) && array.length >= 1) {
          result = true;
        }
      }

      return result;
    }
  }, {
    key: 'validateRecurrence',
    value: function validateRecurrence(events) {
      var result = true;
      events.forEach(function (event) {
        if (event.end_date && !event.recurrence) {
          result = false;
        }
      });
      return result;
    }
  }, {
    key: 'validateTask',
    value: function validateTask() {
      var result = false;
      if (this.state.message && this.state.message.trim() !== '' && this.state.name.trim() !== '') {
        if (this.validateArray(this.state.events) && this.validateArray(this.state.contacts)) {
          result = true;
        }
      }
      return result && this.validateTaskExecutionDate();
    }
  }, {
    key: 'validateTaskExecutionDate',
    value: function validateTaskExecutionDate() {
      var _this2 = this;

      var result = true;
      this.state.events.map(function (item) {
        var currentTime = (0, _moment2.default)().add(_this2.state._SCHEDULER_OFFSET, 'minutes');
        currentTime.set({ second: 0, millisecond: 0 });
        var executionTime = (0, _moment2.default)('' + item.execution_date);
        executionTime.set({ hour: item.execution_hour, minute: item.execution_minute });
        if (executionTime.isSameOrBefore(currentTime)) {
          result = false;
        }
        return result;
      });
      return result;
    }
  }, {
    key: 'createTask',
    value: function createTask() {
      var _this3 = this;

      var isSpecialCase = this.state.fileStats && this.state.trackable_urls;
      if (this.validateTask()) {
        var cleanContacts = this.state.contacts.map(function (item, index) {
          return (0, _values2.default)(item)[0];
        });

        var cleanEvents = this.props.data.events.map(function (item, index) {
          var newItem = item;
          if (newItem.recurrence && newItem.recurrence.term === 'NO RECURRENT') {
            delete newItem.recurrence;
          } else {
            newItem.start_date = newItem.execution_date;
            delete newItem.execution_date;
            if (!newItem.end_date) {
              var endDate = (0, _moment2.default)().month(12).endOf('month').format("YYYY-MM-DD");
              newItem.end_date = endDate;
            } else {
              var _endDate = (0, _moment2.default)(newItem.end_date).format("YYYY-MM-DD");
              newItem.end_date = _endDate;
            }
          }
          return newItem;
        });
        if (this.validateRecurrence(cleanEvents)) {
          var task = {
            name: this.state.name,
            status: 'ACTIVE',
            account_uid: this.props.account._id,
            events: cleanEvents
          };

          var successfulReturn = {
            pathname: '/success',
            state: {
              goTo: '/messages/calendar',
              goToModal: false,
              message: isSpecialCase ? (0, _translations2.default)('SCHEDULING_IN_PROGRESS') : (0, _translations2.default)('SAVE RECURRENCY'),
              modal: true,
              returnTo: '/messages/add'
            }
          };

          var file = this.state.form;

          if (file) {
            var taskFile = {};

            //const formData = new FormData(this.props.data.dataFile)

            // envio de mensajes por archivo
            taskFile.created_by = this.props.user.username;
            taskFile.file = file;
            taskFile.message = this.state.message;

            //file.scheduled =
            // //recurrencias
            taskFile.scheduled = task;
            taskFile.scheduled.account_uid = this.props.account._id;
            taskFile.scheduled.type = 'FILE';
            //taskFile.account_uid = this.props.account._id
            //task.scheduled.file = dataFile
            file.delete('message');
            file.delete('country_code');

            // file.append('scheduled', JSON.stringify(taskFile.scheduled))
            // file.append('message', this.state.message)
            // file.append('created_by', this.props.user.username)

            if (this.props.data.fileUploadResult) {
              var message_from_file = false;
              if (this.state.message === "Mensaje está contenido dentro del archivo") {
                message_from_file = true;
              }

              var tagList = this.state.tags.map(function (t) {
                return t.value;
              });

              var newFileData = {
                _id: this.props.data.fileUploadResult._id,
                scheduled: taskFile.scheduled,
                file_format: this.validateFileFormat(message_from_file),
                type: taskFile.type,
                channel_type: 'SMS',
                trackable_urls: this.state.trackable_urls,
                tags: tagList.join(',')
              };

              if (!message_from_file) {
                newFileData.message = this.state.message;
              }

              this.props.messageConfirmSendFile(newFileData, _messages.channelTypes.SMS).then(function (newTaskData) {
                _this3.state.tags.forEach(function (tag) {
                  _this3.props.incrementTagFileCount(tag.value, tag.id);
                });
                _this3.props.push(successfulReturn);
              });
            }
          } else {
            //envio mensajes normales
            var _cleanContacts = this.state.contacts.map(function (item) {
              return item.value;
            });

            if (this.props.data.tagsList) {
              task.type = 'TAG';
              task.tag = {
                tag_names: _cleanContacts,
                message: this.state.message
              };
            } else {
              task.type = 'SINGLE';
              task.single = {
                profiles: _cleanContacts,
                message: this.state.message
              };
            }

            this.props.newTask(task).then(function (newTaskData) {
              _this3.props.push(successfulReturn);
            });
          }
        } else {
          console.error('Error - corrup events', this.props.data.events);
          this.setState({ corruptEvents: true });
          alert((0, _translations2.default)('CORRUPT EVENTS RETRY'));
          this.props.data.events = [];
          this.props.handleComponentChange(1, this.props.data);
        }
      } else {
        this.setState({ name: this.state.name });
      }
    }
  }, {
    key: 'validateFileFormat',
    value: function validateFileFormat(message_from_file) {
      var file_format = 'SMS_MESSAGE';
      var file_columns = this.props.data.file_columns || this.state.file_columns;
      var isCustom = this.state.isCustom;
      if (file_columns === 0 && isCustom === true) {
        file_format = 'SMS';
      } else {
        if (isCustom == false && message_from_file === true) {
          file_format = 'SMS_MESSAGE';
        }
        if (isCustom == true) {
          file_format = 'SMS_VARS';
        }
      }
      return file_format;
    }
  }, {
    key: 'editOcurrency',
    value: function editOcurrency() {
      var data = this.state;
      data.tabSelected = 3;
      this.props.handleComponentChange(1, data);
    }
  }, {
    key: 'handleNameChange',
    value: function handleNameChange(e) {
      this.setState({ name: e.target.value });
    }
  }, {
    key: 'displayContacts',
    value: function displayContacts() {
      var result = null;
      if (this.state.contacts) {
        var renderContacts = this.state.contacts.map(function (element) {
          if (typeof element != 'string') {
            return _react2.default.createElement(
              'li',
              { className: _Summary2.default.summaryItem },
              ' ',
              element.descripcion,
              ' '
            );
          } else {
            return _react2.default.createElement(
              'li',
              null,
              ' ',
              (0, _values2.default)(element),
              ' '
            );
          }
        });
        result = _react2.default.createElement(
          'ul',
          { className: _Summary2.default.summaryList },
          renderContacts
        );
      }
      return result;
    }
  }, {
    key: 'handleOption',
    value: function handleOption(task, event) {
      var taskId = task.tmp_uid + task.execution_date;
      var newDisplayEvents = this.state.displayEvents;
      var deleted = false;

      if (newDisplayEvents) {
        if (newDisplayEvents.length > 0) {
          if (newDisplayEvents.indexOf(taskId) > -1) {
            deleted = true;
          }
        }
      }
      if (deleted) {
        var temp = newDisplayEvents.filter(function (value) {
          return value != taskId;
        });
        newDisplayEvents = temp;
      } else {
        newDisplayEvents.push(taskId);
      }

      this.setState((0, _extends3.default)({}, this.state, {
        displayEvents: newDisplayEvents
      }));
    }
  }, {
    key: 'displayOccurrences',
    value: function displayOccurrences() {
      var _this4 = this;

      var result = null;
      if (this.props.data.events) {
        var renderOcurrences = this.props.data.events.map(function (element) {
          var displayDetail = _this4.state.displayEvents.find(function (displayElement) {
            return displayElement === element.tmp_uid + element.execution_date;
          });
          var displayDetailResult = displayDetail === undefined ? false : true;
          var displayHour = element.execution_hour + ':' + ('' + (element.execution_minute < 10 ? '0' + element.execution_minute : '' + element.execution_minute));
          return _react2.default.createElement(
            'div',
            null,
            _react2.default.createElement(_components.VirtualListItemSummary, {
              icon: _react2.default.createElement(md.settings, { size: 25, color: '#747474' }),
              date: element.execution_date,
              hour: displayHour,
              text: element.recurrence.label,
              display: true,
              onClick: _this4.handleOption.bind(_this4, element)
            }),
            displayDetailResult ? _react2.default.createElement(_components.VirtualListItemSummaryDetail, {
              startDate: element.execution_date,
              endDate: element.end_date ? (0, _moment2.default)(element.end_date).format('YYYY-MM-DD') : (0, _translations2.default)('NO DATE'),
              hour: displayHour,
              recurrence: element.recurrence
            }) : null
          );
        });
        result = _react2.default.createElement(
          'div',
          { className: _Summary2.default.OccurrenceList },
          renderOcurrences
        );
      }
      return result;
    }
  }, {
    key: 'editContacts',
    value: function editContacts() {
      var data = this.state;
      var props = {
        pathname: '/messages/add',
        state: {
          modal: true,
          loading: true,
          modalSize: { height: '600px', width: '900px' },
          returnTo: '/messages/',
          data: data,
          isEdit: true
        }
      };
      if (this.props.data.tagsList) {
        props.pathname = '/messages/addTags';
      } else {
        props.pathname = '/messages/add';
      }
      this.props.push(props);
    }
  }, {
    key: 'render',
    value: function render() {

      var contactCount = (0, _keys2.default)(this.state.contacts).length;
      // const columns = [{
      //   Header: _('SUMMARY DATES TEXT'),
      //   accessor: 'execution_date',
      //   minWidth: 60,
      //   Cell: props => <div>{(props.row.execution_date ? props.row.execution_date : props.row.start_date)}</div>
      // }, {
      //   Header: _('HOUR'),
      //   accessor: 'execution_hour',
      //   maxWidth: 40,
      //   Cell: props => <div>{props.row.execution_hour}:{this.state.events[0].execution_minute < 10 ? `0${this.state.events[0].execution_minute}` : this.state.events[0].execution_minute}</div>
      // },
      // {
      //   Header: _('RECURRENCY'),
      //   accessor: 'recurrence.label',
      //   maxWidth: 100
      // }
      // ]
      var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Summary2.default);
      return _react2.default.createElement(
        'div',
        { className: _Summary2.default.resumeContainer + ' ' + classesTheme },
        _react2.default.createElement(
          'div',
          { className: _Summary2.default.containerTitle },
          (0, _translations2.default)('MESSAGE SCHEDULER TITLE')
        ),
        _react2.default.createElement(
          'div',
          { className: _Summary2.default.panelsContainer },
          _react2.default.createElement(
            'div',
            { className: _Summary2.default.resumePanels },
            _react2.default.createElement(
              'div',
              { className: _Summary2.default.text },
              _react2.default.createElement(
                'div',
                { className: _Summary2.default.panelTitle },
                (0, _translations2.default)('PANELS')
              ),
              _react2.default.createElement(
                'div',
                { className: _Summary2.default.panelContent },
                this.state.fileStats ? (0, _translations2.default)('FILE NAME') + ":" : null,
                !this.state.fileStats ? (0, _translations2.default)('CONTACTS ON PANEL') + ':' : null,
                ' \xA0 ',
                this.state.contacts && !this.state.fileStats ? _react2.default.createElement(
                  'span',
                  { className: _Summary2.default.orangeText },
                  contactCount
                ) : null,
                this.displayContacts()
              )
            ),
            this.state.form ? null : _react2.default.createElement(_components.Button, { onClick: this.editContacts, name: (0, _translations2.default)('Editar panel'), className: _Summary2.default.panelButton })
          ),
          _react2.default.createElement(
            'div',
            { className: _Summary2.default.resumeMessage },
            _react2.default.createElement(
              'div',
              { className: _Summary2.default.text },
              _react2.default.createElement(
                'div',
                { className: _Summary2.default.panelTitle },
                (0, _translations2.default)('MESSAGE')
              ),
              _react2.default.createElement(
                'div',
                { className: _Summary2.default.panelContent },
                _react2.default.createElement(
                  'div',
                  { className: _Summary2.default.messageText },
                  this.props.fileFormat == 'PHONE' ? this.props.data.customMessage : this.state.message
                )
              )
            ),
            this.state.form ? null : _react2.default.createElement(_components.Button, {
              name: (0, _translations2.default)('Editar mensaje'),
              onClick: this.editContacts,
              className: _Summary2.default.panelButton
            }),
            this.state.fileStats ? _react2.default.createElement(
              'div',
              { className: _Summary2.default.totalContainer },
              _react2.default.createElement(
                'div',
                { className: _Summary2.default.totalMessages },
                this.props.data.fileStats ? (0, _translations2.default)('TOTAL MESSAGES') + ":" : null,
                ' ',
                _react2.default.createElement(
                  'span',
                  { className: _Summary2.default.orangeText },
                  this.props.data.fileStats ? this.props.data.fileStats.total_messages.toLocaleString() : null
                )
              )
            ) : null
          ),
          _react2.default.createElement(
            'div',
            { className: _Summary2.default.resumeOcurrency },
            _react2.default.createElement(
              'div',
              { className: _Summary2.default.text },
              _react2.default.createElement(
                'div',
                { className: _Summary2.default.panelTitle },
                (0, _translations2.default)('OCCURRENCE')
              ),
              _react2.default.createElement(
                'div',
                { className: _Summary2.default.panelContent },
                this.state.resumeOcurrency,
                this.displayOccurrences()
              )
            ),
            this.props.data.tagsList ? null : _react2.default.createElement(_components.Button, {
              name: (0, _translations2.default)('Editar ocurrencias'),
              className: _Summary2.default.panelButton,
              onClick: this.editOcurrency
            })
          )
        ),
        _react2.default.createElement(
          'div',
          { className: _Summary2.default.containerName },
          _react2.default.createElement('input', {
            type: 'text',
            placeholder: (0, _translations2.default)('NAME PLACEHOLDER CALENDAR'),
            value: this.state.name || '',
            ref: 'name',
            onChange: this.handleNameChange
            // onKeyPress={this.handleSearch}
          })
        ),
        _react2.default.createElement(_components.Button, {
          disabled: !this.validateTask() && !this.state.corruptEvents,
          name: !this.validateTask() && !this.state.corruptEvents ? (0, _translations2.default)('INCOMPLETE') : (0, _translations2.default)('MESSAGE SCHEDULER BUTTON'),
          className: !this.validateTask() && !this.state.corruptEvents ? _Summary2.default.notReadyButton : _Summary2.default.readyButton,
          onClick: this.createTask
        }),
        !this.validateTaskExecutionDate() && _react2.default.createElement(
          'div',
          { className: _Summary2.default.invalidSchedule },
          (0, _translations2.default)('INVALID SCHEDULE HOUR P1'),
          ' ',
          (0, _getTimeFromMinutes2.default)(this.state._SCHEDULER_OFFSET),
          ' ',
          (0, _translations2.default)('INVALID SCHEDULE HOUR P2')
        ),
        this.state.corruptEvents && _react2.default.createElement(
          'div',
          { className: _Summary2.default.invalidSchedule },
          (0, _translations2.default)('CORRUPT EVENTS RETRY')
        )
      );
    }
  }]);
  return ScheduleWizardSummary;
}(_react2.default.Component), (_applyDecoratedDescriptor(_class2.prototype, 'createTask', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'createTask'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'editOcurrency', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'editOcurrency'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleNameChange', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleNameChange'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'handleOption', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'handleOption'), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, 'editContacts', [_coreDecorators.autobind], (0, _getOwnPropertyDescriptor2.default)(_class2.prototype, 'editContacts'), _class2.prototype)), _class2)) || _class);
exports.default = ScheduleWizardSummary;
module.exports = exports['default'];