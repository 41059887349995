'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _dec, _dec2, _class, _class2, _temp;

var _components = require('components');

var _themeEnabled = require('helpers/themeEnabled');

var _reports = require('redux/modules/reports');

var _Menu = require('@material-ui/core/Menu');

var _Menu2 = _interopRequireDefault(_Menu);

var _MenuItem = require('@material-ui/core/MenuItem');

var _MenuItem2 = _interopRequireDefault(_MenuItem);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _translations = require('translations');

var _translations2 = _interopRequireDefault(_translations);

var _reduxConnect = require('redux-connect');

var _reactRedux = require('react-redux');

var _account = require('redux/modules/account');

var _status = require('redux/modules/status');

var _subaccounts = require('redux/modules/subaccounts');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _numbro = require('numbro');

var _numbro2 = _interopRequireDefault(_numbro);

var _reachHistory = require('../../helpers/reachHistory');

var _Harassment = require('./Harassment.scss');

var _Harassment2 = _interopRequireDefault(_Harassment);

var _useBasename = require('../../helpers/useBasename');

var _useBasename2 = _interopRequireDefault(_useBasename);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var md = {
    pieChart: require('react-icons/lib/md/pie-chart')
};
var Harassment = (_dec = (0, _reduxConnect.asyncConnect)([{
    key: 'account',
    promise: function promise(_ref) {
        var _ref$store = _ref.store,
            dispatch = _ref$store.dispatch,
            getState = _ref$store.getState;

        var state = getState();
        if (!state.account.data.reach) {
            return dispatch((0, _account.getAccount)());
        }
        return null;
    }
}, {
    key: 'account_status',
    promise: function promise(_ref2) {
        var _ref2$store = _ref2.store,
            dispatch = _ref2$store.dispatch,
            getState = _ref2$store.getState;

        var state = getState();
        if (!state.account.account_id) {
            return dispatch((0, _status.getAccountStatus)());
        }
        return null;
    }
}]), _dec2 = (0, _reactRedux.connect)(function (state) {
    return {
        subaccounts: state.subaccounts.list,
        accountReach: state.auth.current_account
    };
}, {
    getHarassmentTraffic: _reports.getHarassmentTraffic,
    getAccount: _account.getAccount,
    sendTransactionReportRequest: _reports.sendTransactionReportRequest,
    get: _subaccounts.getSubaccountList,
    push: _reachHistory.push
}), _dec(_class = _dec2(_class = (_temp = _class2 = function (_ReportList) {
    (0, _inherits3.default)(Harassment, _ReportList);

    function Harassment(props) {
        (0, _classCallCheck3.default)(this, Harassment);

        var _this = (0, _possibleConstructorReturn3.default)(this, (Harassment.__proto__ || (0, _getPrototypeOf2.default)(Harassment)).call(this, props));

        _this.state = {
            anchorEl: null,
            subaccounts: [{ _id: _this.props.accountReach.account_uid, name: _this.props.accountReach.name, checked: true }],
            report_data: '',
            histogram: [],
            kpis: [],
            account_kpi_value: '',
            account_kpi: 0,
            cycle_buttons: [{ value: 0, name: "Ciclo actual", status: "active" }, { value: -1, name: "Último ciclo", status: "" }, { value: -2, name: "Penúltimo ciclo", status: "" }, { value: -3, name: "Antepenúltimo ciclo", status: "" }],
            cycle: 0,
            filter: false,
            pointStart: '',
            total_sms_account: [],
            total_sms: '',
            total_contacts: '',
            prom_value: '',
            max_sms: '',
            account_type: _this.props.account_status.account_type,
            startDate: _this.props.account_status.current_package.valid_since,
            endDate: _this.props.account_status.current_package.valid_thru
        };
        return _this;
    }

    (0, _createClass3.default)(Harassment, [{
        key: 'setButtonActive',
        value: function setButtonActive(value) {
            var buttons = this.state.cycle_buttons;
            buttons.forEach(function (button) {
                if (button.value == value) {
                    button.status = "active";
                } else {
                    button.status = "";
                }
            });
            this.setState({
                cycle_buttons: buttons
            });
        }
    }, {
        key: 'updateReport',
        value: function updateReport(cycle) {
            this.setState({
                cycle: cycle
            });

            var start = void 0;
            var end = void 0;

            if (cycle != null) {
                var _props$account_status = this.props.account_status.current_package,
                    valid_since = _props$account_status.valid_since,
                    valid_thru = _props$account_status.valid_thru;

                start = (0, _moment2.default)(valid_since).add(cycle, 'months');
                end = (0, _moment2.default)(valid_thru).add(cycle, 'months');
            } else {
                var _state = this.state,
                    startDate = _state.startDate,
                    endDate = _state.endDate;

                start = (0, _moment2.default)(startDate).format("YYYY-MM-DD HH:mm:ss");
                end = (0, _moment2.default)(endDate).format("YYYY-MM-DD HH:mm:ss");
            }

            this.getHarassmentTraffic(start, end, cycle);
        }
    }, {
        key: 'getHarassmentTraffic',
        value: function getHarassmentTraffic(dateStart, dateEnd, cycle) {
            var _this2 = this;

            var params = {
                valid_since: (0, _moment2.default)(dateStart),
                valid_thru: (0, _moment2.default)(dateEnd),
                accounts: this.state.subaccounts.filter(function (account) {
                    return account.checked;
                }).map(function (ids) {
                    return ids._id;
                }),
                account_type: this.props.account_status.account_type,
                kpi_value: this.state.account_kpi_value
            };

            this.props.getHarassmentTraffic(params).then(function (result) {
                var included_subaccounts = _this2.state.subaccounts || [];
                var account_sms = result.sub_accounts ? result.sub_accounts.buckets : [];
                included_subaccounts.forEach(function (element) {
                    element.total = 0;
                });
                account_sms.forEach(function (account) {
                    included_subaccounts.forEach(function (accountlist) {
                        if (account.key == accountlist._id) {
                            accountlist.total = account.doc_count;
                        }
                    });
                });
                _this2.setButtonActive(cycle);
                _this2.setState({
                    startDate: params.valid_since,
                    endDate: params.valid_thru,
                    report_data: result,
                    histogram: result.result.map(function (value) {
                        return value['doc_count'];
                    }),
                    kpis: result.kpi_harassment,
                    account_kpi: result.account_kpi_count,
                    pointStart: result.result[0].key,
                    total_sms: result.total_sms,
                    total_sms_account: included_subaccounts.sort(function (a, b) {
                        return b.total - a.total;
                    }),
                    total_contacts: result.total_destinations_month,
                    prom_value: result.total_sms / result.total_destinations_month,
                    max_sms: result.max_sms_to_contact_month
                });
            });
        }
    }, {
        key: 'handleInformation',
        value: function handleInformation() {
            var _props$account_status2 = this.props.account_status.current_package,
                valid_since = _props$account_status2.valid_since,
                valid_thru = _props$account_status2.valid_thru;


            this.getHarassmentTraffic(valid_since, valid_thru, this.state.cycle);
        }
    }, {
        key: 'componentWillMount',
        value: function componentWillMount() {
            var _this3 = this;

            this.props.getAccount().then(function (value) {
                var configs = value.reach.configs ? value.reach.configs : null;
                var kpiValue = configs.harassmentKpi ? configs.harassmentKpi : 0;

                _this3.setState({
                    account_kpi_value: kpiValue
                });

                if (_this3.props.account_status.account_type == 'Reseller') {
                    _this3.props.get().then(function (values) {
                        var filtered_accounts = values.filter(function (account) {
                            if (account.reach.configs && account.reach.configs.harassment !== "excluded") return account;
                        });
                        _this3.setState({
                            subaccounts: filtered_accounts.map(function (account) {
                                return { _id: account._id, name: account.name, checked: true };
                            })
                        });
                        _this3.handleInformation();
                    });
                } else {
                    _this3.handleInformation();
                }
            });
        }
    }, {
        key: 'handleSubmit',
        value: function handleSubmit(type) {
            var _this4 = this;

            var _state2 = this.state,
                startDate = _state2.startDate,
                endDate = _state2.endDate;


            var accounts = this.state.subaccounts.map(function (ids) {
                return ids._id;
            });
            return this.props.sendTransactionReportRequest(startDate, endDate, this.state.tag, this.state.msisdn, "OUT", true, this.state.template_uid, this.state.routerInfo, accounts, type).then(function () {
                _this4.props.push({
                    pathname: '/success',
                    state: {
                        goTo: _this4.props.location.pathname,
                        goToModal: false,
                        message: (0, _translations2.default)('REPORT REQUESTED'),
                        modal: true,
                        returnTo: '/reports/'
                    }
                });
            });
        }
    }, {
        key: 'renderCycleButtons',
        value: function renderCycleButtons() {
            var _this5 = this;

            return _react2.default.createElement(
                'div',
                { className: _Harassment2.default.cycleContainer },
                this.state.cycle_buttons.map(function (button) {
                    return _react2.default.createElement(
                        'div',
                        { key: button.value, className: button.status == "active" ? _Harassment2.default.cycleButtonActive : _Harassment2.default.cycleButtonNormal,
                            onClick: function onClick() {
                                return _this5.updateReport(button.value);
                            } },
                        button.name
                    );
                })
            );
        }
    }, {
        key: 'onDateChange',
        value: function onDateChange() {
            var _this6 = this;

            this.setState((0, _extends3.default)({}, this.state, {
                filtering: false
            }), function () {
                _this6.updateReport(null);
            });
        }
    }, {
        key: 'monthChecker',
        value: function monthChecker(startDate, endDate) {
            var days = void 0;

            if (startDate && !endDate) days = this.state.endDate.diff(startDate, 'days');
            if (!startDate && endDate) days = endDate.diff(this.state.startDate, 'days');
            if (startDate && endDate) days = endDate.diff(startDate, 'days');

            return days;
        }
    }, {
        key: 'handleDateChange',
        value: function handleDateChange(cycle, startDate, endDate) {
            var valid = this.monthChecker(startDate, endDate);
            if (valid > 31) {
                console.error("Máximo permitido son 31 días");
                this.setState((0, _extends3.default)({}, this.state, {
                    startDate: startDate,
                    endDate: endDate
                }));
            } else {
                if (startDate && endDate) {
                    this.setState((0, _extends3.default)({}, this.state, {
                        startDate: startDate,
                        endDate: endDate
                    }), this.onDateChange);
                } else if (startDate) {
                    this.setState((0, _extends3.default)({}, this.state, {
                        startDate: startDate
                    }), this.onDateChange);
                } else if (endDate) {
                    this.setState((0, _extends3.default)({}, this.state, {
                        endDate: endDate
                    }), this.onDateChange);
                }
            }
        }
    }, {
        key: 'renderFilter',
        value: function renderFilter() {
            var start = (0, _moment2.default)(this.state.startDate);
            var end = (0, _moment2.default)(this.state.endDate);

            if (this.state.filter) return _react2.default.createElement(_components.ReportFilter, { type: 'harassment', startDate: start, endDate: end, onDateChange: this.handleDateChange.bind(this) });
            if (!this.state.filter) return _react2.default.createElement('div', { style: { padding: '8px' } });
        }
    }, {
        key: 'renderChart',
        value: function renderChart() {
            var assets = (0, _themeEnabled.getAssetStyle)();
            var values = [{
                "name": (0, _translations2.default)('MESSAGES_SENT'),
                "pointInterval": 24 * 3600 * 1000,
                "pointStart": this.state.pointStart,
                "data": this.state.histogram,
                "color": assets.colorChart
            }];

            return _react2.default.createElement(_components.GenericLineChart, { series: values, height: 240 });
        }
    }, {
        key: 'renderKpis',
        value: function renderKpis() {
            var account_kpi = this.state.account_kpi_value ? 'Contactos que recibieron m\xE1s de ' + this.state.account_kpi_value + ' SMS al mes: ' + this.state.account_kpi : " ";
            var kpis = this.state.kpis;
            var assets = (0, _themeEnabled.getAssetStyle)();
            var values = [{
                "showInLegend": false,
                "data": kpis,
                "color": assets.colorChart,
                "dataLabels": {
                    "enabled": true,
                    "color": 'black',
                    "format": '{point.y: ,.0f}',
                    "style": {
                        "fontSize": '16px',
                        "fontFamily": 'Verdana, sans-serif'
                    }
                }
            }];
            return _react2.default.createElement(_components.GenericBarChart, { series: values, height: 240, title: account_kpi, titley: "Contactos", tooltip: false });
        }
    }, {
        key: 'formatNumber',
        value: function formatNumber(n) {
            return (0, _numbro2.default)(n).format('3a');
        }
    }, {
        key: 'renderContent',
        value: function renderContent(dataCol1, dataCol2) {

            return _react2.default.createElement(
                'div',
                { className: _Harassment2.default.content },
                _react2.default.createElement(
                    'div',
                    { className: _Harassment2.default.data },
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.text },
                        _react2.default.createElement(
                            'div',
                            { className: _Harassment2.default.head },
                            dataCol1.title
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _Harassment2.default.value },
                            this.formatNumber(dataCol1.value)
                        )
                    ),
                    _react2.default.createElement('div', { className: _Harassment2.default.separator }),
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.text },
                        _react2.default.createElement(
                            'div',
                            { className: _Harassment2.default.head },
                            dataCol2.title
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _Harassment2.default.value },
                            this.formatNumber(dataCol2.value)
                        )
                    )
                )
            );
        }
    }, {
        key: 'accountSelect',
        value: function accountSelect(checked, account) {
            var list = this.state.total_sms_account.map(function (subaccount) {
                if (subaccount._id == account._id) subaccount.checked = checked;
                return subaccount;
            });

            this.setState({
                total_sms_account: list,
                subaccounts: list
            });
        }
    }, {
        key: 'selectAll',
        value: function selectAll(checked) {
            var list = this.state.total_sms_account.map(function (subaccount) {
                subaccount.checked = checked;
                return subaccount;
            });
            this.setState({
                total_sms_account: list,
                subaccounts: list
            });
        }
    }, {
        key: 'reloadData',
        value: function reloadData() {
            this.updateReport(this.state.cycle);
        }
    }, {
        key: 'renderAccountList',
        value: function renderAccountList() {
            var _this7 = this;

            var account_list = this.state.total_sms_account.sort(function (a, b) {
                return b.total - a.total;
            });
            return _react2.default.createElement(
                'div',
                { className: _Harassment2.default.accounts },
                _react2.default.createElement(
                    'div',
                    { className: _Harassment2.default.title },
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.accountListTitle },
                        _react2.default.createElement(
                            'div',
                            null,
                            _react2.default.createElement('input', { type: 'checkbox', defaultChecked: true, onChange: function onChange(e) {
                                    return _this7.selectAll(e.target.checked);
                                } }),
                            '  Cuenta'
                        ),
                        _react2.default.createElement(
                            'div',
                            null,
                            'Cant. SMS'
                        )
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: _Harassment2.default.accountsContainer },
                    account_list.map(function (account, index) {
                        return _react2.default.createElement(
                            'div',
                            { key: index, className: _Harassment2.default.accountList },
                            _react2.default.createElement(
                                'div',
                                null,
                                _react2.default.createElement('input', { type: 'checkbox', checked: account.checked, onChange: function onChange(e) {
                                        return _this7.accountSelect(e.target.checked, account);
                                    } }),
                                ' ',
                                account.name
                            ),
                            _react2.default.createElement(
                                'div',
                                null,
                                account.total ? (0, _numbro2.default)(account.total).format() : 0
                            )
                        );
                    })
                ),
                _react2.default.createElement(
                    'div',
                    { onClick: function onClick() {
                            return _this7.reloadData();
                        }, className: _Harassment2.default.button },
                    'Aplicar filtro'
                )
            );
        }
    }, {
        key: 'renderStats',
        value: function renderStats() {
            var reseller = _Harassment2.default.statsGridContainerReseller;
            var noReseller = _Harassment2.default.statsGridContainer;

            var report = this.state;

            var contactBox = {
                title: 'SMS enviados por contacto',
                prom_value: { title: 'Promedio', value: report.prom_value ? report.prom_value : 0 },
                max_value: { title: 'Máximo', value: report.max_sms ? report.max_sms : 0 }
            };
            var smsBox = {
                title: 'Contactos vs SMS enviados',
                contacts: { title: 'Contactos', value: report.total_contacts ? report.total_contacts : 0 },
                totalSms: { title: 'SMS Enviados', value: report.total_sms ? report.total_sms : 0 }
            };
            return _react2.default.createElement(
                'div',
                { className: this.state.account_type == 'Reseller' ? reseller : noReseller },
                _react2.default.createElement(
                    'div',
                    { className: _Harassment2.default.chartkpi },
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.title },
                        (0, _translations2.default)("MESSAGES_SENT"),
                        ' por contacto'
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.content },
                        this.renderKpis()
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: _Harassment2.default.chart },
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.title },
                        'Tr\xE1fico diario ',
                        this.state.account_type == 'Reseller' ? 'de todas las cuentas' : ''
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.content },
                        this.renderChart()
                    )
                ),
                _react2.default.createElement(
                    'div',
                    { className: _Harassment2.default.info1 },
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.title },
                        contactBox.title
                    ),
                    this.renderContent(contactBox.prom_value, contactBox.max_value)
                ),
                _react2.default.createElement(
                    'div',
                    { className: _Harassment2.default.info2 },
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.title },
                        smsBox.title
                    ),
                    this.renderContent(smsBox.contacts, smsBox.totalSms)
                ),
                this.state.account_type == 'Reseller' ? this.renderAccountList() : ''
            );
        }
    }, {
        key: 'render',
        value: function render() {
            var _this8 = this;

            var startDateFormatted = this.state.startDate ? (0, _moment2.default)(this.state.startDate).format('YYYY-MM-DD') : (0, _moment2.default)().format('YYYY-MM-DD');
            var endDateFormatted = this.state.endDate ? (0, _moment2.default)(this.state.endDate).format('YYYY-MM-DD') : (0, _moment2.default)().format('YYYY-MM-DD');

            var classesTheme = (0, _themeEnabled.getThemeEnabledStyle)(_Harassment2.default);
            return _react2.default.createElement(
                'div',
                { className: _Harassment2.default.report },
                _react2.default.createElement(
                    'div',
                    { className: classesTheme },
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.bar },
                        _react2.default.createElement(
                            'div',
                            { className: _Harassment2.default.icon },
                            _react2.default.createElement(md.pieChart, { size: 20, color: '#FFF' })
                        ),
                        _react2.default.createElement(
                            'div',
                            { className: _Harassment2.default.title },
                            (0, _translations2.default)('Reporte General de hostigamiento'),
                            _react2.default.createElement(
                                'span',
                                { onClick: function onClick() {
                                        return _this8.setState({ filter: !_this8.state.filter });
                                    }, className: this.state.filter ? _Harassment2.default.lower : _Harassment2.default.lower_active },
                                (0, _translations2.default)('Desde'),
                                ' ',
                                startDateFormatted,
                                ' ',
                                (0, _translations2.default)('TO'),
                                ' ',
                                endDateFormatted,
                                _react2.default.createElement('img', { className: _Harassment2.default.download_icon, src: (0, _useBasename2.default)('/static/arrow_down.svg'), alt: '' })
                            )
                        ),
                        _react2.default.createElement(SimpleMenu, { submit: this.handleSubmit, state: this.state, props: this.props })
                    ),
                    _react2.default.createElement(
                        'div',
                        { className: _Harassment2.default.body },
                        this.renderFilter(),
                        this.renderStats()
                    )
                )
            );
        }
    }]);
    return Harassment;
}(_components.ReportList), _class2.propTypes = {
    account: _propTypes2.default.object
}, _temp)) || _class) || _class);
exports.default = Harassment;

var SimpleMenu = function (_React$Component) {
    (0, _inherits3.default)(SimpleMenu, _React$Component);

    function SimpleMenu(props) {
        (0, _classCallCheck3.default)(this, SimpleMenu);

        var _this9 = (0, _possibleConstructorReturn3.default)(this, (SimpleMenu.__proto__ || (0, _getPrototypeOf2.default)(SimpleMenu)).call(this, props));

        _this9.state = {
            anchorEl: null
        };

        _this9.handleClick = function (event) {
            _this9.setState({ anchorEl: event.currentTarget });
        };

        _this9.handleClose = function () {
            _this9.setState({ anchorEl: null });
        };

        return _this9;
    }

    (0, _createClass3.default)(SimpleMenu, [{
        key: 'render',
        value: function render() {
            var _this10 = this;

            var anchorEl = this.state.anchorEl;


            return _react2.default.createElement(
                'div',
                null,
                _react2.default.createElement(
                    'div',
                    {
                        'aria-owns': anchorEl ? 'simple-menu' : undefined,
                        'aria-haspopup': 'true',
                        onClick: function onClick(e) {
                            return _this10.handleClick(e);
                        },
                        className: _Harassment2.default.download
                    },
                    'Solicitar Reportes',
                    _react2.default.createElement('img', { className: _Harassment2.default.download_icon, src: (0, _useBasename2.default)('/static/download_icon.svg'), alt: '' })
                ),
                _react2.default.createElement(
                    _Menu2.default,
                    {
                        id: 'simple-menu',
                        anchorEl: anchorEl,
                        open: Boolean(anchorEl),
                        onClose: this.handleClose
                    },
                    _react2.default.createElement(
                        _MenuItem2.default,
                        { onClick: function onClick() {
                                return _this10.props.submit('group_identifiers');
                            } },
                        'Reporte de resumen por destino'
                    ),
                    _react2.default.createElement(
                        _MenuItem2.default,
                        { onClick: function onClick() {
                                return _this10.props.submit('');
                            } },
                        'Reporte detalle totalidad SMS'
                    )
                )
            );
        }
    }]);
    return SimpleMenu;
}(_react2.default.Component);

module.exports = exports['default'];